import Vue from "vue";
import VueRouter from "vue-router";
import { getToken } from "../utils/auth.js";

Vue.use(VueRouter);

const originPush = VueRouter.prototype.push;
const originReplace = VueRouter.prototype.replace;

VueRouter.prototype.push = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originPush.call(this, location, () => {});
  } else {
    originPush.call(this, location, onComplete, onAbort);
  }
};
VueRouter.prototype.replace = function (location, onComplete, onAbort) {
  if (!onComplete && !onAbort) {
    originReplace.call(this, location, () => {});
  } else {
    originReplace.call(this, location, onComplete, onAbort);
  }
};

const routes = [
  {
    path: "/",
    component: () => import("views/Home"),
    redirect: "/Home"
  },
  //首页
  {
    name: "Home",
    path: "/Home",
    component: () => import("views/Home"),
    meta: {
      show: true
    }
  },
  //溯源
  {
    name: "TraceTheSource",
    path: "/traceTheSource",
    component: () => import("views/home/traceTheSource"),
    meta: {
      show: true
    }
  },
  //存证
  {
    name: "Store",
    path: "/store",
    component: () => import("views/home/store/store"),
    meta: {
      show: true
    },
    redirect: "store/storeRightBox",
    children: [
      {
        name: "StoreRightBox",
        path: "storeRightBox",
        component: () => import("views/home/store/storeRightBox"),
        meta: {
          show: true
        }
      },
      {
        name: "StoreRightList",
        path: "storeRightList",
        component: () => import("views/home/store/storeRightList"),
        meta: {
          show: true
        }
      },
      {
        name: "StoreTraceList",
        path: "storeTraceList",
        component: () => import("views/home/store/StoreTraceList"),
        meta: {
          show: true
        }
      }
    ]
  },
  //版权收益
  {
    name: "copyrightIncome",
    path: "/copyrightIncome",
    component: () => import("views/home/copyright-income/CopyrightIncome"),
    meta: {
      show: true
    }
  },
  //版权服务
  {
    name: "copyrightService",
    path: "/copyrightService",
    component: () => import("views/home/copyright-service/CopyrightService"),
    meta: {
      show: true
    },
    redirect: "copyrightService/copyrightPractice",
    children: [
      //版权实务
      {
        name: "CopyrightPractice",
        path: "copyrightPractice",
        component: () =>
          import(
            "views/home/copyright-service/copyright-practice/CopyrightPractice"
          ),
        meta: {
          show: true
        },
        redirect: "copyrightPractice/copyrightPracticeIndex",
        children: [
          //版权实务首页
          {
            name: "CopyrightPracticeIndex",
            path: "copyrightPracticeIndex",
            component: () =>
              import(
                "views/home/copyright-service/copyright-practice/CopyrightPracticeIndex"
              ),
            meta: {
              show: true
            }
          },
          //我的关注
          {
            name: "MyAttention",
            path: "myAttention",
            component: () =>
              import(
                "views/home/copyright-service/copyright-practice/MyAttention"
              ),
            meta: {
              show: true
            }
          },
          //代办委托
          {
            name: "AgencyEntrustment",
            path: "agencyEntrustment",
            component: () =>
              import(
                "views/home/copyright-service/copyright-practice/AgencyEntrustment"
              ),
            meta: {
              show: true
            },
            redirect: "agencyEntrustment/AgencyEntrustmentIndex",
            children: [
              {
                name: "AgencyEntrustmentIndex",
                path: "agencyEntrustmentIndex",
                component: () =>
                  import(
                    "views/home/copyright-service/copyright-practice/AgencyEntrustmentIndex"
                  ),
                meta: {
                  show: true
                }
              },
              // 代办委托详情
              {
                name: "AgencyEntrustmentDetailsContractor",
                path: "AgencyEntrustmentDetailsContractor",
                component: () =>
                  import(
                    "views/home/copyright-service/copyright-practice/AgencyEntrustmentDetailsContractor"
                  ),
                meta: {
                  show: true
                }
              },
              {
                name: "AgencyEntrustmentDetailsUser",
                path: "AgencyEntrustmentDetailsUser",
                component: () =>
                  import(
                    "views/home/copyright-service/copyright-practice/AgencyEntrustmentDetailsUser"
                  ),
                meta: {
                  show: true
                }
              },
              // 执业规范
              {
                name: "PracticeNorms",
                path: "practiceNorms",
                component: () =>
                  import(
                    "views/home/copyright-service/copyright-practice/practiceNorms"
                  ),
                meta: {
                  show: true
                }
              }
            ]
          },
          //订单中心
          {
            name: "OrderCenter",
            path: "orderCenter",
            component: () =>
              import(
                "views/home/copyright-service/copyright-practice/OrderCenter"
              ),
            meta: {
              show: true
            }
          },
          // 订单评论
          {
            name: "OrderComments",
            path: "orderComments",
            props: {},
            component: () =>
              import(
                "views/home/copyright-service/copyright-practice/OrderComments"
              ),
            meta: {
              show: true
            }
          },
          // 订单详情
          {
            name: "OrderDetails",
            path: "orderDetails",
            props: {},
            component: () =>
              import(
                "views/home/copyright-service/copyright-practice/OrderDetails"
              ),
            meta: {
              show: true
            }
          },
          //我的收藏
          {
            name: "MyCollection",
            path: "myCollection",
            component: () =>
              import(
                "views/home/copyright-service/copyright-practice/MyCollection"
              ),
            meta: {
              show: true
            }
          }
        ]
      },
      // 信息汇编
      {
        name: "InfoCompile",
        path: "infoCompile",
        component: () =>
          import("views/home/copyright-service/infoCompile/infoCompile"),
        meta: {
          show: true
        },
        redirect: "infoCompile/infoCompileIndex",
        children: [
          {
            name: "InfoCompileIndex",
            path: "infoCompileIndex",
            component: () =>
              import(
                "views/home/copyright-service/infoCompile/infoCompileIndex"
              ),
            meta: {
              show: true
            }
          },
          {
            name: "AllText",
            path: "alltext",
            component: () =>
              import("views/home/copyright-service/infoCompile/alltext"),
            meta: {
              show: true
            }
          }
        ]
      },
      // 实名认证
      {
        name: "RealName",
        path: "realName",
        component: () =>
          import("views/home/copyright-service/realName/realName"),
        meta: {
          show: true
        },
        redirect: "realName/personalRealName",
        children: [
          //个人实名
          {
            name: "PersonalRealName",
            path: "personalRealName",
            component: () =>
              import(
                "views/home/copyright-service/realName/personal/PersonalRealName"
              ),
            meta: {
              show: true
            }
          },
          //填写个人实名信息
          {
            name: "FillPersonalInfo",
            path: "fillPersonalInfo",
            component: () =>
              import(
                "views/home/copyright-service/realName/personal/FillPersonalInfo"
              ),
            meta: {
              show: true
            }
          },
          //填写个人实名 2-补充信息
          {
            name: "SupplementInfo",
            path: "supplementInfo",
            component: () =>
              import(
                "views/home/copyright-service/realName/personal/SupplementInfo"
              ),
            meta: {
              show: true
            }
          },
          //填写个人实名 3-提交成功
          {
            name: "RealNameSuccess",
            path: "realNameSuccess",
            component: () =>
              import(
                "views/home/copyright-service/realName/personal/RealNameSuccess"
              ),
            meta: {
              show: true
            }
          },
          //个人已实名
          {
            name: "PersonalAlreadyRealName",
            path: "personalAlreadyRealName/:realName",
            component: () =>
              import(
                "views/home/copyright-service/realName/personal/PersonalAlreadyRealName"
              ),
            meta: {
              show: true
            },
            props: true
          },
          //机构实名
          {
            name: "OrganizationRealName",
            path: "organizationRealName",
            component: () =>
              import(
                "views/home/copyright-service/realName/organization/OrganizationRealName"
              ),
            meta: {
              show: true
            }
          },
          //填写机构实名信息
          {
            name: "FillOrganizationInfo",
            path: "fillOrganizationInfo",
            component: () =>
              import(
                "views/home/copyright-service/realName/organization/FillOrganizationInfo"
              ),
            meta: {
              show: true
            }
          },
          //机构已实名
          {
            name: "OrganizationAlreadyRealName",
            path: "organizationAlreadyRealName/:examine?/:realName",
            component: () =>
              import(
                "views/home/copyright-service/realName/organization/OrganizationAlreadyRealName"
              ),
            meta: {
              show: true
            },
            props: true
          }
        ]
      },
      // 收录确权
      {
        name: "Included",
        path: "included",
        component: () =>
          import("views/home/copyright-service/included/included"),
        meta: {
          show: true
        }
      },
      //  指纹核验
      {
        name: "Fingerprint",
        path: "fingerprint",
        component: () =>
          import("views/home/copyright-service/fingerprint/fingerprint"),
        meta: {
          show: true
        }
      },
      // 会员服务
      {
        name: "VipServe",
        path: "vipServe",
        component: () =>
          import("views/home/copyright-service/vipServe/vipServe"),
        meta: {
          show: true
        }
      },
      //产品详情
      {
        name: "CopyrightPracticeDetail",
        path: "copyrightPracticeDetail/:id?",
        component: () =>
          import(
            "views/home/copyright-service/copyright-practice/CopyrightPracticeDetail"
          ),
        meta: {
          show: true
        }
      }
    ]
  },
  {
    name: "Feedback",
    path: "/Feedback",
    meta: {
      show: true
    },
    component: () => import("views/Feedback/Feedback")
  },
  //关于我们
  {
    name: "aboutus",
    path: "/aboutus",
    meta: {
      show: true
    },
    component: () => import("views/aboutus/index")
  },
  //下载引导
  {
    name: "DownloadGuide",
    path: "/downloadGuide",
    component: () => import("views/downloadguide/DownloadGuide"),
    meta: {
      show: true
    }
  },
  //网盘
  {
    name: "disk",
    path: "/disk",
    meta: {
      show: true
    },
    component: () => import("views/disk/index")
  },

  //账户记录
  {
    name: "AccountRecords",
    path: "/accountRecords",
    meta: {
      show: true
    },
    component: () => import("views/account-records/AccountRecords")
  },
  //授权书
  {
    name: "letterOfAuthorization",
    path: "/letterOfAuthorization",
    component: () =>
      import("views/letter-of-authorization/LetterOfAuthorization")
  }
  // //暂定：历史溯源报告
  // {
  //   name: "historicalTracing",
  //   path: "/historicalTracing",
  //   component: () => import("views/home/HistoricalTracing"),
  // },
  // //暂定：进行中溯源报告
  // {
  //   name: "traceTheSourceReporting",
  //   path: "/traceTheSourceReporting",
  //   component: () => import("views/home/TraceTheSourceReporting"),
  // },
];

const router = new VueRouter({
  mode: "history",
  routes
});

// router.beforeEach((to,from,next) => {
//   if(to.path == "/home"){

//   }
// });
/* 添加路由守卫 */
router.beforeEach((to, from, next) => {
  //   /* 首先获取访问的路径 */
  const path = to.path;
  /* 没有令牌可以访问的页面 */
  if (
    path == "/" ||
    path == "/Home" ||
    path == "/aboutus" ||
    path == "/copyrightService/copyrightPractice/copyrightPracticeIndex" ||
    path == "/copyrightService/infoCompile/infoCompileIndex" ||
    path == "/copyrightService/vipServe" ||
    path == "/realName" ||
    path == "/downloadGuide" ||
    path == "/Feedback"
  ) {
    return next();
  }
  // 没有令牌不可以访问的页面
  if (!getToken("auth_token")) {
    // new Vue().$message({ message: "请先登录！", type: "error" });
    // return next("/Home");
    // return next("/login");
  }
  // 有令牌后页面都可以访问
  next();
});
export default router;
