<template>
  <div id="app">
    <Header v-show="$route.meta.show" />
    <div class="container">
      <router-view />
    </div>
    <!--公用实名提示弹窗-->
    <RealNamePopup />

    <!-- 底部 -->
    <Footer v-if="$route.name !== 'DownloadGuide'" />
    <!-- 加载 -->
    <DiskDemo />
  </div>
</template>

<script>
import Header from "components/header/Header.vue";
import Footer from "components/footer/footer.vue";
import RealNamePopup from "components/RealNamePopup";
import DiskDemo from "components/loading.vue";
import { mapState } from "vuex";
export default {
  data() {
    return {
      loader: null,
    };
  },
  computed: {
    ...mapState({
      playLifeLoading: (state) => state.loading,
    }),
  },
  watch: {
    playLifeLoading(status) {
      if (status === true) {
        this.loader = this.$loading({
          lock: true,
          text: "加载中...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.5)",
        });
      } else {
        this.loader && this.loader.close();
      }
    },
  },
  components: {
    Header,
    RealNamePopup,
    Footer,
    DiskDemo,
  },
  created() {},
};
</script>

<style lang="scss" scoped>
#app {
  .container {
    width: 1450px;
    margin: 30px auto 40px;
  }
}
</style>
<style>
.el-message.el-message--success {
  width: auto !important;
  min-width: 0;
}
.el-message.el-message--error {
  width: auto !important;
  min-width: 0;
}
.el-message.el-message--info {
  width: auto !important;
  min-width: 0;
}
</style>
