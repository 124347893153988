import request from "./request";

// // 条件查询消息
// export function queryMessage(data) {
//   return request({
//     url: "/remindmessage/remind-msg/query",
//     method: "post",
//     data,
//   });
// }

//网盘中会员开通信息
export function diskRightsOpenInfo() {
  return request({
    url: "/product/diskRightsOpenInfo",
    method: "get",
  });
}

// 查询未读消息
export function FindSysMsg() {
  return request({
    url: "/remindmessage/remind-msg/findSysMsg",
    method: "post",
  });
}
// 获取消息列表
export function findMsgInfoList(data) {
  return request({
    url: "/remindmessage/remind-msg/findMsgInfoList",
    method: "post",
    data,
  });
}

// 用户消息
export function FindFriendMsg(data) {
  return request({
    url: "/remindmessage/remind-msg/findFriendMsg",
    method: "post",
    data,
  });
}
// 发送消息
export function Addmessage(data) {
  return request({
    url: "/remindmessage/remind-msg/add",
    method: "post",
    data,
  });
}

// 读消息
export function Readmessage(data) {
  return request({
    url: "/remindmessage/remind-msg/read",
    method: "PUT",
    data,
  });
}

// 批量删除
export function DelBatchMessage(data) {
  return request({
    url: "remindmessage/remind-msg/delBatch",
    method: "POST",
    data,
  });
}
//  获取用户的推荐码
export function findUserRecommendCode() {
  return request({
    url: "recommendcode/findUserRecommendCode",
    method: "POST",
  });
}
