import request from "./request";

//检查用户是否实名
export function checkUserRealName() {
  return request({
    url: "/realname/checkUserRealname",
  });
}

//添加实名认证
export function addRealName(data) {
  return request({
    url: "/realname/addRealname",
    method: "post",
    data,
  });
}

//注销实名认证信息
export function cancelRealName(data) {
  return request({
    url: "/realname/cancelRealname",
    method: "post",
    data,
  });
}

//修改实名认证账号绑定的手机号
export function updateRealNameMobile(data) {
  return request({
    url: "/realname/updateRealnameMobile",
    method: "post",
    data,
  });
}

//添加为副实名账号
export function addDeputyAccount(data) {
  return request({
    url: "/realname/addDeputyAccount",
    method: "post",
    data,
  });
}

//纠错-重新核对-上传身份证
export function realNameErrorCorrect(data) {
  return request({
    url: "/realname/addRealnameFeedback",
    method: "post",
    data,
  });
}

// 文件生成电子数据指纹
export function existevidenceCheck(data) {
  return request({
    url: "/existevidence/check",
    method: "post",
    data,
  });
}

//验证时间凭证
export function getFingerprint(data) {
  return request({
    url: "/existevidence/getFingerprint",
    method: "post",
    data,
  });
}

// 分页搜索查询
export function page(data) {
  return request({
    url: "/product/page",
    method: "post",
    data,
  });
}

// 根据ID查询商品
export function searchID(params) {
  return request({
    url: "/product/one",
    method: "get",
    // data,
    params,
  });
}

// 搜索查询商品文件
export function searchFile(data) {
  return request({
    url: "/product/product-file/search",
    method: "post",
    data,
  });
}

// 搜索查询商品规格
export function searchSpecification(data) {
  return request({
    url: "/product/product-specification/search",
    method: "post",
    data,
  });
}

// 新增订单
export function orderAdd(data) {
  return request({
    url: "/product/order/add",
    method: "post",
    data,
  });
}

// 分页搜索订单
export function orderPage(data) {
  return request({
    url: "/product/order/allOrder",
    method: "post",
    data,
  });
}

// 授权生成支付二维码
export function payOtherOrderPay(data) {
  return request({
    url: "/pay/orderPay",
    method: "post",
    data,
  });
}

// 调用商品微信支付的查询订单接口
export function PaywechatOrderQueryQrcode(data) {
  return request({
    url: "/pay/wechatOrderQueryQrcode",
    method: "post",
    data,
  });
}

//信息汇编搜索
export function queryOfficicalWord(data) {
  return request({
    url: "/copyright/queryOfficicalWord",
    method: "post",
    data,
  });
}

//菜单列表
export function list(data) {
  return request({
    url: "/menubase/list",
    method: "post",
    data,
  });
}

//获取全文
export function getAlltext(data) {
  return request({
    url: "/system/openCkWord",
    method: "post",
    data,
  });
}

// 添加汇编
export function addAssemblys(data) {
  return request({
    url: "/system/addDocTempInfo",
    method: "post",
    data,
  });
}

//汇编成册
export function writerMergeWord() {
  return request({
    url: "/system/writerMergeWord",
    method: "post",
    responseType: "blob",
  });
}

// 获取汇编成册数量
export function getAssemblyNums(data) {
  return request({
    url: "/system/userDocTempCount",
    method: "post",
    data,
  });
}

//清空汇编
export function delDocTempInfo() {
  return request({
    url: "/system/delDocTempInfo",
    method: "post",
  });
}

// 信息汇编-文件下载
export function fileDownload(data) {
  return request({
    url: "/system/writerCkWord",
    method: "post",
    data,
    responseType: "blob",
  });
}
export function downloadFile(data) {
  // console.log(data);
  return request({
    url: "/common/downloadMD5",
    method: "post",
    data,
    responseType: "blob",
  });
}

// 删除订单
export function orderDelete(data) {
  return request({
    url: "/product/order/delete",
    method: "DELETE",
    data,
  });
}

// 转存网盘
export function saveNetdisk(data) {
  return request({
    url: "/system/shareToIcloud",
    method: "post",
    data,
  });
}

// 转存网盘
export function Collection(data) {
  return request({
    url: "/favorite/collection",
    method: "post",
    data,
  });
}
// 获取待办委托列表
export function getagencyEntrustmentList(data) {
  return request({
    url: "/task/pageList",
    method: "post",
    data,
  });
}

// 获取待办委托详情
export function getcheckDetail(data) {
  return request({
    url: "/task/checkDetail",
    method: "post",
    data,
  });
}

// 获取反馈列表
export function getfeedbackList(data) {
  return request({
    url: "/practice/taskdetail-feedback/findFeedbackList",
    method: "post",
    data,
  });
}

// 增删收藏商品
export function addOrDel(data) {
  return request({
    url: "/productcollection/addOrDel",
    method: "post",
    data,
  });
}

// 获取收藏列表
export function collectList(data) {
  return request({
    url: "/productcollection/list",
    method: "post",
    data,
  });
}

// 发表评论
export function add(data) {
  return request({
    url: "/productevaluate/add",
    method: "post",
    data,
  });
}

// 获取评论列表
export function commentList(params) {
  return request({
    url: "/productevaluate/list",
    method: "get",
    params,
  });
}

//获取法律法规列表数据
export function findLawsRegulationList() {
  return request({
    url: "statutefile/list",
    method: "get",
  });
}

// 上传反馈
export function uploadList(data) {
  return request({
    url: "/taskdetailfile/fileBatchUpload",
    method: "post",
    data,
  });
}

// 获取版权实务规格
export function FindProcess(data) {
  return request({
    url: "/project/findProcessAndArea",
    method: "post",
    data,
  });
}
// 获取版权实务承办人
export function FindPromoter(data) {
  return request({
    url: "/project/findPromoter",
    method: "post",
    data,
  });
}

// 新增版权实务订单
export function AddCopyrightPractice(data) {
  return request({
    url: "/product/order/addCopyrightPractice",
    method: "post",
    data,
  });
}

// 撤销反馈
export function recallFeedback(data) {
  return request({
    url: "/practice/taskdetail-feedback/deleteFeedback",
    method: "delete",
    data,
  });
}

// 修改时间
export function modifyTime(data) {
  return request({
    url: "/task/update",
    method: "post",
    data,
  });
}

// 获取用户信息
export function getUserInfo(data) {
  return request({
    url: "/user/getUserInfoById",
    method: "post",
    data,
  });
}

// 获取优惠接口
export function findDiscount(data) {
  return request({
    url: "/recommendcode/findDiscount",
    method: "post",
    data,
  });
}
export function findDiscountInfoByCode(data) {
  return request({
    url: "/recommendcode/findDiscountInfoByCode",
    method: "post",
    data,
  });
}

// 绑定推荐码
export function bind(data) {
  return request({
    url: "recommendcode/bind",
    method: "post",
    data,
  });
}

// 下载版权诉讼指引
export function exportProjectFile(params) {
  return request({
    url: "/projectdetail/exportProjectFile",
    method: "get",
    params,
    responseType: "blob",
  });
}
