import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "store/index";
import "utils/directives";
import "assets/scss/index.scss";
import "./plugins/element";
import uploader from "vue-simple-uploader";
import {
	MessageBox
} from "element-ui";
Vue.use(uploader);
Vue.config.productionTip = false;
Vue.prototype.$confirm = MessageBox.confirm;
// 全局eventbus
let EventBus = new Vue();
Object.defineProperties(Vue.prototype, {
	$bus: {
		get() {
			return EventBus;
		},
	},
});
//跳转页面到顶部
router.afterEach((to, from, next) => {
	window.scrollTo(0, 0)
})
new Vue({
	router,
	store,
	render: (h) => h(App),
}).$mount("#app");
