import request from "api/request";
import oauthrequest from "api/oauthrequest";

//密码登录
export function passLogin(data) {
  return request({
    url: "/user/pwdLogin",
    method: "post",
    data,
  });
}

//验证码登录
export function codeLogin(data) {
  return oauthrequest({
    url: `/oauth/token?grant_type=sms_code&mobile=${data.mobile}&code=${data.code}&scope=all`,
    method: "post",
    data,
  });
}
// 用户注册
export function register(data) {
  return oauthrequest({
    url: "/user/register",
    method: "post",
    data,
  });
}
//发送验证码,获取验证码ID
export function getMobileAchCaptchas(data) {
  return oauthrequest({
    url: "/user/getMobileAchCaptchas",
    method: "post",
    data,
  });
}
// 获取用户信息
export function getuserInfo(data) {
  return request({
    url: "/user/getUserInfo",
    method: "get",
    data,
  });
}
// 密码登录
export function passwordLogin(data) {
  return oauthrequest({
    url: `/oauth/token?grant_type=password&username=${data.mobile}&password=${data.password}&scope=all`,
    method: "post",
  });
}
// 修改密码
export function update(data) {
  return request({
    url: "/user/forgotPassword",
    method: "post",
    data,
  });
}
// 关注列表
export function findFocusList(data) {
  return request({
    url: "/user/findFocusList",
    method: "post",
    data,
  });
}
// 取消关注
export function focus(data) {
  return request({
    url: "/user/focus",
    method: "post",
    data,
  });
}
// 修改资料
export function userInfoUpd(data) {
  return request({
    url: "/user/userInfoUpd",
    method: "put",
    data,
  });
}
