<template>
  <div class="real-name-popup">
    <el-dialog
      :visible.sync="showRealNameTips"
      :modal="false"
      :close-on-click-modal="false"
      title="提示"
      class="real-name-dialog"
    >
      <div class="content">您还未实名,请先完成实名认证!</div>
      <div class="footer">
        <span
          class="now"
          @click="
            $router.push({ path: '/copyrightService/realName' });
            showRealNameTips = false;
          "
          >现在认证</span
        >
        <span class="next" @click="showRealNameTips = false">下次认证</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "RealNamePopup",
  data() {
    return {
      showRealNameTips: false
    };
  },
  created() {
    this.$bus.$on("realNamePopup", () => {
      this.showRealNameTips = true;
    });
  },
  destroyed() {
    this.$bus.$off("");
  }
};
</script>

<style lang="scss" scoped>
.real-name-popup {
  .real-name-dialog {
    ::v-deep .el-dialog {
      @include flex-center(column);
      justify-content: normal;
      align-items: normal;
      top: 50%;
      transform: translateY(-50%);
      margin-top: 0 !important;
      width: 285px;
      height: 200px;
      background: #ffffff;
      box-shadow: 0px 0px 16px 0px rgba(98, 102, 107, 0.2);
      .el-dialog__header {
        padding: 10px 10px;
        .el-dialog__title {
          font-size: 12px;
          font-weight: 400;
          color: #999999;
        }
        .el-dialog__headerbtn {
          top: 12px;
          right: 11px;
        }
      }
      .el-dialog__body {
        @include flex-center(column);
        justify-content: normal;
        align-items: normal;
        flex: 1;
        padding: 20px;
        .content {
          @include flex-center;
          align-items: normal;
          flex: 1;
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 26px;
        }
        .footer {
          @include flex-between;
          @include noselect;
          > span {
            padding: 0 8px;
            font-size: 14px;
            font-weight: 500;
            color: #4587ff;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>
