import Vue from "vue";

import {
  Message,
  Button,
  ButtonGroup,
  Dialog,
  Progress,
  Collapse,
  CollapseItem,
  Pagination,
  Table,
  TableColumn,
  Select,
  Option,
  OptionGroup,
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Carousel,
  CarouselItem,
  Breadcrumb,
  BreadcrumbItem,
  Rate,
  Tabs,
  TabPane,
  Image,
  Form,
  FormItem,
  Input,
  DatePicker,
  Popover,
  Upload,
  Radio,
  RadioGroup,
  Badge,
  Tree,
  Tooltip,
  Loading,
  Timeline,
  TimelineItem,
  Card,
  Tag,
  MessageBox,
  Steps,
  Step,
  Switch,
  Row,
  Col
} from "element-ui";
Vue.use(Switch);
Vue.use(Step);
Vue.use(Steps);
Vue.use(Button);
Vue.use(Card);
Vue.use(Tag);
Vue.use(Upload);
Vue.use(Timeline);
Vue.use(TimelineItem);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(ButtonGroup);
Vue.use(Dialog);
Vue.use(Progress);
Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Pagination);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Checkbox);
Vue.use(CheckboxButton);
Vue.use(CheckboxGroup);
Vue.use(Carousel);
Vue.use(CarouselItem);
Vue.use(Breadcrumb);
Vue.use(BreadcrumbItem);
Vue.use(Rate);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Image);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Input);
Vue.use(DatePicker);
Vue.use(Popover);
Vue.use(Badge);
Vue.use(Tree);
Vue.use(Tooltip);
Vue.use(Row);
Vue.use(Col);
Vue.prototype.$message = Message;
Vue.prototype.$loading = Loading.service;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;
