<template>
  <div class="footer">
    深圳写手智能科技有限公司&nbsp;Shenzhen&nbsp;writer&nbsp;Intelligent&nbsp;Technology&nbsp;Co.,&nbsp;Ltd&nbsp;&nbsp;<a
      href="https://beian.miit.gov.cn/"
      target="_blank"
      >粤ICP备20062435号</a
    >
  </div>
</template>

<script>
export default {
  name: "Footer",
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
.footer {
  z-index: 3;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 15px;
  background-color: #fbfbfb;
  text-align: center;
  color: #cccccc;
  font-size: 12px;

  a {
    color: #cccccc;
  }
}
</style>
