import axios from "axios";
import { getToken } from "@/utils/auth";
import store from "store/index";

const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL:
    process.env.NODE_ENV == "production"
      ? `${process.env.VUE_APP_BASE_API}api`
      : "/api",
  timeout: 60 * 30 * 1000,
});

service.interceptors.request.use(
  (config) => {
    console.log("============service.interceptors.request.use");

    let TokenObj = getToken();
    if (TokenObj) {
      TokenObj = JSON.parse(TokenObj);
      config.headers.Authorization = `${TokenObj.token_type} ${TokenObj.access_token}`;
    } else {
      config.headers.Authorization = process.env.VUE_APP_DEFAULT_TOKEN;
    }

    return config;
  },
  (error) => {
    console.log(error);
    store.commit("playLifeLoading",false);
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    } else {
      Promise.reject();
    }
  },
  (error) => {
    console.log(error);
    store.commit("playLifeLoading",false);
    return Promise.reject();
  }
);

service.interceptors.response.use(
  (response) => {
    if (response.status === 200) {
      return response.data;
    } else {
      Promise.reject();
    }
  },
  (error) => {
    console.log(error);
    return Promise.reject();
  }
);

export const loginRequestApi = (option) => {
  return service({
    method: option.method,
    url: `${option.url}`,
    data: option.data,
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const requestApi = (option) => {
  if (option.isJson && JSON.stringify(option.data) !== {}) {
    return service({
      method: option.method,
      url: `${option.url}`,
      data: option.data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } else if (option.isJson && JSON.stringify(option.data) === {}) {
    return service({
      method: option.method,
      url: `${option.url}`,
      data: option.data,
      headers: {
        "Content-Type": "application/json",
      },
    });
  } else if (!option.isJson && JSON.stringify(option.data) !== {}) {
    return service({
      method: option.method,
      url: `${option.url}`,
      params: option.data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  } else if (!option.isJson && JSON.stringify(option.data) === {}) {
    return service({
      method: option.method,
      url: `${option.url}`,
    });
  }
};
