import request from "./request";
import { requestApi } from "@/utils/request";

//发送验证码,获取验证码ID
export function getMobileAchCaptchas(data) {
  return request({
    url: "/common/getMobileAchCaptchas",
    method: "post",
    data,
  });
}

//发送验证码,获取验证码ID
export function getMobileAchCaptcha(data) {
  return request({
    url: "/common/getMobileAchCaptchas",
    method: "post",
    data,
  });
}

//校验验证码
export function checkMobileAchCaptchas(data) {
  return request({
    url: "/common/checkMobileAchCaptchas",
    method: "post",
    data,
  });
}

//上传文件
export function upload(data) {
  return request({
    url: "/common/upload",
    method: "post",
    data,
  });
}

// 下载文件
export function downloadFile(data) {
  return request({
    url: "/common/downloadFile",
    method: "post",
    data,
    responseType: "blob",
  });
}

//返回预览地址
export function previewUrl(data) {
  return request({
    url: "/common/preview",
    method: "post",
    data,
  });
}

//html to word
export function preserveWord(data) {
  return request({
    url: "/common/htmlToWordDownload",
    method: "post",
    data,
    responseType: "blob",
  });
}
