var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.isDialogShow)?_c('div',{staticClass:"registrationDialog"},[_c('div',{staticClass:"registrationDialog_box"},[_c('div',{staticClass:"title_img"}),_c('div',{staticClass:"close_box",on:{"click":_vm.closeClick}}),_c('div',{staticClass:"text_img"}),_vm._m(0),_c('div',{staticClass:"input_box"},[_c('div',{staticClass:"registered"},[_c('div',{staticClass:"text"},[_vm._v("注册链接")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"link_test",attrs:{"title":'http://passport.writemall.com/register?code=' + _vm.code}},[_vm._v(" http://passport.writ... ")]),_c('div',{staticClass:"copy_text",attrs:{"title":'http://passport.writemall.com/register?code=' + _vm.code},on:{"click":function($event){return _vm.copyClick('http://passport.writemall.com/register?code=' + _vm.code)}}},[_vm._v(" 复制 ")])])]),_c('div',{staticClass:"registered"},[_c('div',{staticClass:"text"},[_vm._v("专属优惠码")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"link_test"},[_vm._v(_vm._s(_vm.code))]),_c('div',{staticClass:"copy_text",on:{"click":function($event){return _vm.copyClick(_vm.code)}}},[_vm._v("复制")])])]),_c('div',{staticClass:"registered"},[_c('div',{staticClass:"text"},[_vm._v("营销文本")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"link_test",attrs:{"title":'写手智能科技公司专属注册链接，点击  http://passport.writemall.com/register?code=' +
              _vm.code +
              '  ' +
              _vm.discountMsg}},[_vm._v(" 写手智能科技公司专... ")]),_c('div',{staticClass:"copy_text",attrs:{"title":'写手智能科技公司专属注册链接，点击  http://passport.writemall.com/register?code=' +
              _vm.code +
              '  ' +
              _vm.discountMsg},on:{"click":function($event){return _vm.copyClick(1)}}},[_vm._v(" 复制 ")])])]),_c('div',{staticClass:"registered"},[_c('div',{staticClass:"text"},[_vm._v("软文链接")]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"link_test",attrs:{"title":'http://passport.writemall.com/?code=' + _vm.code}},[_vm._v(" http://passport.wri.. ")]),_c('div',{staticClass:"copy_text",attrs:{"title":'写手智能科技公司专属注册链接，点击  http://passport.writemall.com/?code=' +
              _vm.code},on:{"click":function($event){return _vm.copyClick('http://passport.writemall.com/?code=' + _vm.code)}}},[_vm._v(" 复制 ")])])])]),_c('div',{staticClass:"log_box"})])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"text_box"},[_c('div',{staticClass:"text_1"},[_vm._v(" 1.点击专属链接注册的账号，可获赠5000字符；并绑定为粉丝账号，为期一年。 ")]),_c('div',{staticClass:"text_1"},[_vm._v(" 2.使用优惠码在购买VIP会员、语境库、金句库、模板库等，可以获得专属折扣，未被绑定的用户账号使用优惠码则被绑定为粉丝账号，为期一年。 ")]),_c('div',{staticClass:"text_1"},[_vm._v(" 3.注册链接、优惠码，均可以单独嵌入软文，或者放在合适的展示平台。 ")])])}]

export { render, staticRenderFns }