<template>
  <div v-if="loading" class="box1">
    <div class="box"></div>
    <div class="btn1">
      <div class="btn2">
        {{ reminder }}
      </div>
      <div class="nub">{{ nub }}%</div>
    </div>
    <div class="loader-ring"></div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      reminder: "",
      nub: 0
    };
  },
  created() {
    let that = this;
    this.$bus.$on("loading1", function (title) {
      that.reminder = title + "...";
    });
  },
  mounted() {
    var that = this;
    let timeId = null;

    this.$bus.$on("loading", function (data, title, estimate) {
      if (data || data == 0) {
        if (data === Number(data)) {
          that.loading = true;
          that.nub = data;

          if (data == 100) {
            setTimeout(() => {
              that.loading = title;
              that.nub = 0;
              that.reminder = "";
            }, 2000);
          }
          return;
        } else {
          that.loading = data;
        }
      } else {
        that.loading = data;
        that.nub = 0;
        that.reminder = "";
        clearInterval(timeId);
      }

      if (title) {
        if (title.length > 13) {
          that.reminder = that.GuolvText(title);
        } else {
          that.reminder = title + "...";
        }
      } else {
        that.reminder = "";
      }
      if (estimate) {
        timeId = setInterval(() => {
          if (that.nub < 90) {
            that.nub = that.nub + (Math.floor(Math.random() * 10) + 1);
          } else {
            clearInterval(timeId);
          }
        }, 600);
      } else {
        that.nub = 100;
        setTimeout(() => {
          that.loading = false;
          that.nub = 0;
          this.reminder = "";
        }, 1000);
      }

      if (data) {
        document.getElementsByTagName("body")[0].style = "overflow: hidden";
      } else {
        document.body.removeAttribute("style");
      }
    });
  },
  methods: {
    GuolvText(value) {
      // if (!value) return ''
      if (value.length > 13) {
        console.log(value);
        return value.slice(0, 13) + "...";
      }
      return value;
    }
  }
};
</script>

<style lang="scss" scoped>
.bg {
  overflow: hidden;
}
.box1 {
  width: 100%;
  height: 100%;
}
.nub {
  margin-top: 30px;
  font-size: 30px;
}
.btn2 {
  line-height: 26px;
}
.box {
  position: absolute;
  left: 0;
  top: 0;
  background-color: #000;
  opacity: 0.5;
  width: 100%;
  height: calc(100vh + 150px);
  z-index: 9999;
}
.loader-ring {
  top: calc(50% - 100px);
  left: 50%;
  pointer-events: none;
}
#long-tail {
  visibility: hidden;
}
.btn1 {
  padding: 40px 0 0;
  box-sizing: border-box;
  position: absolute;
  top: calc(50% - 70px);
  left: calc(50% - 70px);
  color: #fff;
  /* color: #fff; */
  width: 140px;
  height: 140px;

  font-weight: 600;
  font-size: 18px;
  text-align: center;
  z-index: 999999;
}

// .btn1:before {
//   content: "";
//   cursor: pointer;
//   /* background: #1A1A1A; */
//   width: 140px;
//   height: 140px;
//   position: absolute;
//   top: -15px;
//   left: -15px;
//   z-index: 999999;
//   border-radius: 999px;
// }
.loader-ring {
  position: absolute;
  height: 120px;
  width: 120px;
  border-radius: 0 100% 0 0;
  border: 1px solid white;
  border-width: 0px 5px 0px 0px;
  box-shadow: 5px 1px 2px -4px white, inset -4px 1px 3px -4px white;
  transform-origin: 0px 100%;
  animation: rotate 1s linear infinite;
  z-index: 999999;
}

.loader-ring:after {
  position: absolute;
  height: 1px;
  width: 1px;
  top: 100%;
  left: calc(100% + 2px);
  border-radius: 100%;
  border-width: 0;
  background: white;
  box-shadow: 0px 0 3px 5px white;
  content: "";
  z-index: 999999;
}

#long-tail:checked ~ .loader-ring:before {
  position: absolute;
  height: 200%;
  width: 200%;
  top: -1px;
  left: calc(-100% - 1px);
  border-radius: 100%;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-width: 1px 0 0;
  transform-origin: 0px 0px;
  content: "";
  z-index: 999999;
}

#long-tail:checked ~ .loader-ring {
  animation-duration: 0.8s;
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
