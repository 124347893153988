<template>
  <div>
    <!-- 注册弹窗 -->
    <div v-if="register_flag || showdale" class="register-main">
      <div @click="registerCancel" class="login-cancel">
        <i class="el-icon-close"></i>
      </div>
      <div class="register-wrap">
        <div class="register-logo"></div>
        <!-- 输入框-->
        <div class="register-input">
          <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-yonghu.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input
                type="text"
                @blur="Re_name"
                name="mobile"
                v-model="phone_number"
                placeholder="请输入手机号"
              />
            </div>
          </div>
          <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-suo.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input
                :type="R_show_pass ? 'text' : 'password'"
                name="password"
                maxLength="20"
                v-model="R_password"
                placeholder="请设置6-20位密码"
              />
            </div>
            <div class="register-input-type">
              <img
                v-if="!R_show_pass"
                :src="require('assets/images/login-changetype.png')"
                alt=""
                @click="R_show_pass = true"
              />
              <img
                v-else
                :src="require('assets/images/login-yan-h.png')"
                alt=""
                @click="R_show_pass = false"
              />
            </div>
          </div>
          <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-suo.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input
                :type="show_pass_again ? 'text' : 'password'"
                name="password"
                maxLength="20"
                v-model="password_again"
                placeholder="请再次输入密码"
              />
            </div>
            <div class="register-input-type">
              <img
                v-if="!show_pass_again"
                :src="require('assets/images/login-changetype.png')"
                alt=""
                @click="show_pass_again = true"
              />
              <img
                v-else
                :src="require('assets/images/login-yan-h.png')"
                alt=""
                @click="show_pass_again = false"
              />
            </div>
          </div>
          <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-codeico.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input type="hidden" value="" id="rcodeId" />
              <input type="text" v-model="R_verif" placeholder="请输入验证码" />
            </div>
            <div
              class="register-input-sendcode"
              style="cursor: pointer"
              @click="R_sendVerif"
            >
              <span v-if="R_verif_seconds == 60">获取验证码</span>
              <span v-if="R_verif_seconds < 60"
                >已发送({{ R_verif_seconds }})</span
              >
            </div>
          </div>
          <div class="register-input-wrap">
            <div class="register-input-icon">
              <img :src="require('assets/images/login-tuijian.png')" alt="" />
            </div>
            <div class="register-input-content">
              <input
                type="text"
                name="recommendCode"
                id="recommendCode"
                placeholder="请输入推荐码/体验卡（选填）"
              />
            </div>
          </div>
        </div>

        <div class="register-footer">
          <div class="register-footer-tips">
            已有账号，
            <a @click="toLogino" style="cursor: pointer">立即登录</a>
          </div>
          <div
            class="register-footer-btn"
            @click="register"
            style="cursor: pointer"
          >
            注册
          </div>
        </div>
      </div>
    </div>
    <!-- 登录弹窗 -->
    <div v-if="login_flag" class="login-main">
      <div @click="loginCancel" class="login-cancel">
        <i class="el-icon-close"></i>
      </div>
      <div class="login-logo">
        <img src="../../assets/images/logo.png" height="70px" />
      </div>
      <div class="login-wrap">
        <div class="login-mode">
          <input
            class="login-nav login-nav1"
            id="mode1"
            type="radio"
            name="login-nav"
            checked=""
          />
          <input
            class="login-nav login-nav2"
            id="mode2"
            type="radio"
            name="login-nav"
          />
          <input
            class="login-nav login-nav3"
            id="mode3"
            type="radio"
            name="login-nav"
          />
          <input
            class="login-nav login-nav4"
            id="mode4"
            type="radio"
            name="login-nav"
          />
          <ul class="login-mode-nav">
            <li>
              <label for="mode1" @click="loginType = 1">手机登录</label>
            </li>
            <li>
              <label for="mode2" @click="loginType = 2">短信登录</label>
            </li>
            <!-- <li>
              <label for="mode3" id="mob3" @click="loginType = 3"
                >微信登录</label
              >
            </li>
            <li>
              <label for="mode4" class="mob4 mob" @click="loginType = 4"
                >扫码登录</label
              >
            </li> -->
          </ul>
          <div
            class="login-mode-content"
            :style="{
              display: loginType == 1 || loginType == 2 ? 'block' : 'none',
            }"
          >
            <!-- 手机登录 -->
            <div
              class="login-mode-content-mode"
              id="content-mode1"
              :style="{ display: loginType == 1 ? 'flex' : '' }"
            >
              <form id="pwdLoginForm">
                <div class="login-content-inpwrap">
                  <div class="login-content-inpwrap-icon">
                    <img
                      :src="require('assets/images/login-yonghu.png')"
                      alt=""
                    />
                  </div>
                  <div class="login-content-inpwrap-input">
                    <input
                      type="text"
                      id="laccount"
                      name="mobile"
                      v-model="phone_number_mode1"
                      placeholder="请输入手机号"
                    />
                  </div>
                </div>
                <div class="login-content-inpwrap" style="margin-top: 20px">
                  <div class="login-content-inpwrap-icon">
                    <img :src="require('assets/images/login-suo.png')" alt="" />
                  </div>
                  <div class="login-content-inpwrap-input">
                    <input
                      :type="show_pass ? 'text' : 'password'"
                      id="password"
                      name="password"
                      v-model="password"
                      maxLength="20"
                      placeholder="请输入密码"
                    />
                  </div>
                  <div class="login-content-inpwrap-type" data-type="false">
                    <img
                      v-if="!show_pass"
                      :src="require('assets/images/login-changetype.png')"
                      alt=""
                      @click="show_pass = true"
                    />
                    <img
                      v-else
                      :src="require('assets/images/login-yan-h.png')"
                      alt=""
                      @click="show_pass = false"
                    />
                  </div>
                </div>
              </form>
            </div>
            <!-- 短信登录 -->
            <div
              class="login-mode-content-mode"
              id="content-mode2"
              :style="{ display: loginType == 2 ? 'flex' : '' }"
            >
              <form id="codeLoginForm">
                <div class="login-content-inpwrap">
                  <div class="login-content-inpwrap-icon">
                    <img
                      :src="require('assets/images/login-yonghu.png')"
                      alt=""
                    />
                  </div>
                  <div class="login-content-inpwrap-input">
                    <input
                      type="text"
                      id="mobile"
                      name="mobile"
                      v-model="phone_number_mode2"
                      placeholder="请输入手机号"
                    />
                  </div>
                </div>
                <div class="login-content-inpwrap" style="margin-top: 15px">
                  <div class="login-content-inpwrap-icon">
                    <img
                      :src="require('assets/images/login-codeico.png')"
                      alt=""
                    />
                  </div>
                  <div class="login-content-inpwrap-input">
                    <input
                      placeholder="请输入验证码"
                      type="text"
                      id="code"
                      v-model="verif"
                      name="code"
                    />
                  </div>
                  <div
                    class="login-content-inpwrap-sendcode"
                    id="btnSendcode"
                    @click="sendVerif"
                    style="cursor: pointer"
                  >
                    <span v-show="verif_seconds == 60">获取验证码</span>
                    <span v-show="verif_seconds < 60"
                      >已发送({{ verif_seconds }})</span
                    >
                  </div>
                  <input type="hidden" value="" id="lcodeId" name="id" />
                  <!-- <div class="login-content-inpwrap-type">
						  <img src="/images/login/login-changetype.png" alt="">
						</div> -->
                </div>
              </form>
            </div>
          </div>
          <div
            :style="{ display: loginType == 3 ? 'block' : '' }"
            class="login-mode content3"
            id="content-mode3"
            style="margin-left: 15%; margin-top: 30px; width: 50%"
          >
            <div
              class="scanContent"
              id="scan-login"
              style="height: 280px; text-align: center"
            >
              <iframe
                src="https://open.weixin.qq.com/connect/qrconnect?appid=wxb31086d21ce9ad5c&amp;scope=snsapi_login&amp;redirect_uri=https://p.writemall.com/wechat/callBack&amp;state=51TOWIN&amp;login_type=jssdk&amp;self_redirect=default&amp;styletype=&amp;sizetype=&amp;bgcolor=&amp;rst=&amp;style=black&amp;href=https://p.writemall.com/css/wx-qrcode.css"
                frameborder="0"
                scrolling="no"
                width="300px"
                height="400px"
              ></iframe>
            </div>
            <span style="display: none" class="wxPhone"
              >微信扫码成功，请绑定手机号</span
            >
            <div
              class="login-mode-content-mode"
              id="wcontent-mode1"
              style="margin-top: 30px; display: none"
            >
              <!-- display:flex -->
              <form id="wxForm">
                <div class="login-content-inpwrap">
                  <div class="login-content-inpwrap-icon">
                    <img
                      :src="require('assets/images/login-yonghu.png')"
                      alt=""
                    />
                  </div>
                  <div class="login-content-inpwrap-input">
                    <input
                      type="text"
                      id="waccount"
                      name="mobile"
                      v-model="phone_wx"
                      placeholder="请输入绑定手机号"
                    />
                  </div>
                </div>
                <div class="login-content-inpwrap" style="margin-top: 20px">
                  <div class="login-content-inpwrap-icon">
                    <img
                      :src="require('assets/images/login-codeico.png')"
                      alt=""
                    />
                  </div>
                  <div class="login-content-inpwrap-input">
                    <input type="hidden" value="" id="wcodeId" />
                    <input
                      type="text"
                      v-model="verif_wx"
                      placeholder="请输入验证码"
                    />
                  </div>
                  <div
                    class="login-content-inpwrap-sendcode"
                    style="cursor: pointer"
                    @click="sendVerif"
                  >
                    <span v-if="verif_seconds == 60">获取验证码</span>
                    <span v-if="verif_seconds < 60"
                      >已发送({{ verif_seconds }})</span
                    >
                  </div>
                </div>
              </form>
            </div>
            <!-- 底部操作 -->
            <div style="display: none !important" class="login-footer content1">
              <!-- display:flex -->
              <div class="login-auto loginspwd" style="display: flex">
                <input
                  type="checkbox"
                  id="wauto-login"
                  v-model="remenber_password"
                  name="avoidLogin1"
                  value="30"
                />
                <label for="wauto-login">自动登录</label>
              </div>
              <div
                class="login-footer-btn"
                id="wpwdBtn"
                @click="toUpdate"
                style="cursor: pointer; display: block"
              >
                登录
              </div>
            </div>
          </div>
          <div
            :style="{ display: loginType == 4 ? 'block' : '' }"
            class="login-mode content2"
            id="content-mode4"
            style="margin-left: 90px; margin-top: 30px; width: 258px"
          >
            <div
              class="scanContent"
              id="scan-login-mobile"
              style="margin-left: 50px"
            >
              <canvas width="158" height="158"></canvas>
            </div>
            <div
              class="scanInfo"
              id="scan-info"
              style="margin-top: 10px; text-align: center"
            >
              <p id="scan-msg" style="color: #333333">二维码已失效请刷新重试</p>
            </div>
            <div class="scanInfo" style="margin-top: 10px; text-align: center">
              <span
                style="
                  width: 252px;
                  height: 12px;
                  font-size: 12px;
                  font-family: Microsoft YaHei;
                  font-weight: 500;
                  color: #999999;
                "
                >如无法正常扫码登录，请更新写手移动端版本</span
              >
            </div>
            <input
              type="hidden"
              name="qrCodeKey"
              value="ba310aa2-b50b-4d51-b4a5-01ebcb91e01e"
              id="qrCodeKey"
            />
          </div>
        </div>
        <!-- 底部操作 -->
        <div
          :style="{
            display: loginType == 1 || loginType == 2 ? 'flex' : 'none',
          }"
          class="login-tool content1 loginspwd"
        >
          <div class="login-auto loginspwd" style="display: flex">
            <input
              v-if="loginType == 1"
              type="checkbox"
              id="auto-login"
              v-model="remenber_password"
              name="avoidLogin1"
              value="30"
            />
            <label v-if="loginType == 1" for="auto-login">记住密码</label>
          </div>
          <div class="login-aspwd loginspwd" style="display: block">
            <span class="login-forgetpwd" @click="toForgot">忘记密码</span>
          </div>
        </div>
        <div
          :style="{
            display: loginType == 1 || loginType == 2 ? 'flex' : 'none',
          }"
          class="login-footer content1"
        >
          <div class="login-footer-tips">
            还没有注册账号，立即
            <a @click="toRegister" style="cursor: pointer">注册</a>
          </div>
          <div
            class="login-footer-btn"
            id="pwdBtn"
            @click="pwdLogin"
            style="cursor: pointer"
            :style="{ display: loginType == 1 ? 'block' : 'none' }"
          >
            登录
          </div>
          <div
            class="login-footer-btn"
            id="codeBtn"
            style="cursor: pointer"
            :style="{ display: loginType == 2 ? 'block' : 'none' }"
            @click="verifLogin"
          >
            登录
          </div>
        </div>
      </div>
    </div>
    <!-- 忘记密码弹窗 -->
    <div v-if="forgot_flag" class="login-main">
      <div @click="forgotCancel" class="login-cancel">
        <i class="el-icon-close"></i>
      </div>
      <div class="login-logo">
        <img src="../../assets/images/logo.png" height="70px" />
      </div>
      <!-- 输入框 -->
      <div
        class="login-mode-content-mode"
        id="fcontent-mode1"
        style="margin-top: 30px; margin-left: 60px; display: flex"
      >
        <form id="forgotForm">
          <div class="login-content-inpwrap">
            <div class="login-content-inpwrap-icon">
              <img :src="require('assets/images/login-yonghu.png')" alt="" />
            </div>
            <div class="login-content-inpwrap-input">
              <input
                type="text"
                id="faccount"
                name="mobile"
                v-model="phone_forgot"
                placeholder="请输入手机号"
              />
            </div>
          </div>
          <div class="login-content-inpwrap" style="margin-top: 20px">
            <div class="login-content-inpwrap-icon">
              <img :src="require('assets/images/login-suo.png')" alt="" />
            </div>
            <div class="login-content-inpwrap-input">
              <input
                :type="f_show_pass ? 'text' : 'password'"
                id="fpassword"
                name="password"
                maxLength="20"
                v-model="password_forgot"
                placeholder="新密码6-20位字母或数字"
              />
            </div>
            <div class="login-content-inpwrap-type" data-type="false">
              <img
                v-if="!f_show_pass"
                :src="require('assets/images/login-changetype.png')"
                alt=""
                @click="f_show_pass = true"
              />
              <img
                v-else
                :src="require('assets/images/login-yan-h.png')"
                alt=""
                @click="f_show_pass = false"
              />
            </div>
          </div>
          <div class="login-content-inpwrap" style="margin-top: 20px">
            <div class="login-content-inpwrap-icon">
              <img :src="require('assets/images/login-codeico.png')" alt="" />
            </div>
            <div class="login-content-inpwrap-input">
              <input type="hidden" value="" id="fcodeId" />
              <input
                type="text"
                v-model="verif_forgot"
                placeholder="请输入验证码"
              />
            </div>
            <div
              class="login-content-inpwrap-sendcode"
              style="cursor: pointer"
              @click="f_sendVerif"
            >
              <span v-if="f_verif_seconds == 60">获取验证码</span>
              <span v-if="f_verif_seconds < 60"
                >已发送({{ f_verif_seconds }})</span
              >
            </div>
          </div>
        </form>
      </div>
      <!-- 底部操作 -->
      <div style="display: flex" class="login-footer content1">
        <div class="login-footer-tips">
          已有账号，立即
          <a @click="toLogino" style="cursor: pointer">登录</a>
        </div>
        <div
          class="login-footer-btn"
          id="fpwdBtn"
          @click="toUpdate"
          style="cursor: pointer; display: block"
        >
          修改
        </div>
      </div>
    </div>
    <!-- 下载弹窗 -->
    <div v-if="downflag" @mouseleave="downflag = false" class="down">
      <div
        :class="wflag ? 'bg' : ''"
        class="down-item"
        @mouseleave="leaver"
        @mouseenter="enter()"
      >
        <img v-if="wflag" src="../../assets/images/windows.png" />
        <img v-if="!wflag" src="../../assets/images/windows-m.png" />
        <span>Windows</span>
      </div>
      <div
        :class="iflag ? 'bg' : ''"
        class="down-item"
        @mouseleave="leaver"
        @mouseenter="enter()"
        @click="clickIos"
      >
        <img v-if="iflag" src="../../assets/images/ios.png" />
        <img v-if="!iflag" src="../../assets/images/ios-m.png" />
        <span class="ml">Ios</span>
      </div>
      <div
        :class="aflag ? 'bg' : ''"
        class="down-item"
        @mouseleave="leaver"
        @mouseenter="enter()"
        @click="clickIos"
      >
        <img v-if="aflag" src="../../assets/images/android.png" />
        <img v-if="!aflag" src="../../assets/images/android-m.png" />
        <span>Android</span>
      </div>
    </div>
    <!-- 修改信息弹窗 -->
    <div v-if="upFlag" class="means">
      <div class="nav">
        <div class="title">个人资料</div>
        <div @click="upclose" class="close">x</div>
      </div>
      <div class="uphead">
        <div
          class="wrap"
          @mouseover="enterAvatar = true"
          @mouseout="enterAvatar = false"
        >
          <input
            accept="image/*"
            @change="getup"
            ref="file"
            class="upfile"
            type="file"
          />
          <img
            class="head-img"
            :src="userInfoList.imgSrc ? userInfoList.imgSrc : defaultHeadImg"
            @error="imgError(userInfoList)"
          />
          <div
            class="mask"
            @click="handleClickAvatar"
            v-show="enterAvatar"
          ></div>
        </div>
      </div>
      <div class="upitem">
        <div class="item">
          <span>昵&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;称：</span>
          <input v-model="userInfoList.nickName" @blur="lazy" />
          <div class="tip" :class="yes ? 'red' : ''">
            3-7个字符，支持中英文，数字
          </div>
        </div>
        <div class="item mt">
          <span>出生日期：</span>
          <input v-model="userInfoList.birthday" type="date" />
        </div>
      </div>
      <div class="upitem">
        <div class="item-g">
          <span>性&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;别：</span>
          <el-radio v-model="gender" label="0">男</el-radio>
          <el-radio v-model="gender" label="1">女</el-radio>
        </div>
        <div class="item">
          <span>个性签名：</span>
          <input
            placeholder="留下你的签名吧"
            v-model="userInfoList.signature"
          />
        </div>
      </div>
      <div class="upitem">
        <div class="item">
          <span>手&nbsp;机&nbsp;号&nbsp;：</span>
          <input v-model="userInfoList.mobile" />
        </div>
        <div class="item-y">
          <span class="span">验&nbsp;证&nbsp;码&nbsp;：</span>
          <div class="send">
            <div class="login-content-inpwrap-icon icon">
              <img :src="require('assets/images/login-codeico.png')" alt="" />
            </div>
            <div class="login-content-inpwrap-input input">
              <input type="hidden" value="" id="fcodeId" />
              <input
                type="text"
                v-model="verif_up"
                placeholder="请输入验证码"
              />
            </div>
            <div
              class="login-content-inpwrap-sendcode sendcode"
              style="cursor: pointer"
            >
              <span @click="x_sendVerif" v-if="x_verif_seconds == 60"
                >获取验证码</span
              >
              <span v-if="x_verif_seconds < 60"
                >已发送({{ x_verif_seconds }})</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="upitem">
        <div class="item-j">
          <span class="introduce">个人简介：</span>
          <textarea
            v-model="userInfoList.introduction"
            placeholder="介绍一下自己吧"
          ></textarea>
        </div>
      </div>
      <el-button @click="keep" style="margin-left: 635px" type="primary"
        >保存</el-button
      >
      <el-button @click="upclose">取消</el-button>
    </div>
    <!-- 页头 -->
    <div class="wm-antiplagiarism-header">
      <div class="wm-antiplagiarism-header-left">
        <div
          class="wm-antiplagiarism-header-left-logo"
          @click="$router.push('/')"
        >
          <img :src="require('assets/images/logo2.png')" alt="" />
        </div>
        <div class="wm-antiplagiarism-header-left-nav">
          <ul class="nav-list">
            <router-link
              v-for="(item, index) in navLang"
              :key="index"
              :to="{ path: item.path }"
              custom
              v-slot="{ href, isActive }"
            >
              <li
                :class="[
                  'nav-list-item',
                  isActive ? 'nav-list-item-active' : '',
                ]"
              >
                <a :href="href" class="tit">{{ item.title }}</a>
              </li>
            </router-link>
            <li class="nav-list-item">
              <img
                v-if="isUser_info == ''"
                class="img"
                :src="require('assets/images/exclusive-registration/text0.png')"
                alt=""
                @click="$refs['registrationDialog'].isDialogShow = true"
              />
              <img
                v-if="isUser_info && isUser_info.haveid"
                class="img"
                :src="require('assets/images/exclusive-registration/text3.png')"
                alt=""
                @click="$refs['registrationDialog2'].isDialogShow = true"
              />
              <img
                @click="$refs['registrationDialog1'].isDialogShow = true"
                v-if="isUser_info && !isUser_info.haveid"
                class="img"
                :src="require('assets/images/exclusive-registration/text2.png')"
                alt=""
              />
            </li>
          </ul>
        </div>
      </div>
      <div class="wm-antiplagiarism-header-right">
        <!-- <div class="netw" @click="$router.push({ path: '/disk' })">
					<img v-if="isEnterDisk" :src="require('assets/images/network-blue.png')" alt="" />
					<img v-else :src="require('assets/images/network.png')" alt="" />
					<span :class="isEnterDisk ? 'blue' : ''">智能网盘</span>
				</div> -->
        <div @click="go_feedback" class="download">BUG与意见反馈</div>
        <div @click="go_down" class="download">
          <img :src="require('assets/images/download.png')" alt="" />
          立即下载
        </div>

        <div class="user" v-if="isLogin == true" @click="handleUserInfo">
          <el-image
            style="
              width: 43px;
              height: 43px;
              margin-right: 10px;
              border-radius: 50%;
            "
            :src="userInfoList.imgSrc ? userInfoList.imgSrc : defaultHeadImg"
            @error="imgError(userInfoList)"
            fit="cover"
          ></el-image>
          <span class="login-link">{{ userInfoList.nickName }}</span>
        </div>
        <div class="user" v-else>
          <img
            @click="toLogino"
            :src="require('assets/images/user.png')"
            alt=""
          />
          <span class="login-link"
            ><span @click="toLogino">登录</span>/<span @click="toRegister"
              >注册</span
            ></span
          >
        </div>
      </div>

      <!-- 用户信息弹窗 -->
      <el-dialog
        :modal="false"
        top="0"
        :show-close="false"
        :visible.sync="userInfo"
        class="userInfoPopup"
      >
        <div class="user">
          <div class="user_box">
            <div @click="updata" class="headImg">
              <el-image
                @click="updata"
                style="
                  width: 43px;
                  height: 43px;
                  margin-right: 10px;
                  border-radius: 50%;
                  cursor: pointer;
                "
                :src="
                  userInfoList.imgSrc ? userInfoList.imgSrc : defaultHeadImg
                "
                @error="imgError(userInfoList)"
                fit="cover"
              >
              </el-image>
              <div class="grade" v-if="userInfoList.isvip == 1"></div>
              <div
                class="grade vipIcon"
                v-else-if="userInfoList.isvip == 2"
              ></div>
              <div class="grade svipIcon" v-else></div>
            </div>
            <div class="user-text">
              <div class="user-name">{{ userInfoList.nickName }}</div>
              <div class="user-id">ID:{{ userInfoList.id }}</div>
            </div>
            <div class="user-character">
              <span class="txt">字符余额：{{ userInfoList.character }}</span>
            </div>
          </div>

          <div class="marketing" v-if="isUser_info && isUser_info.haveid">
            <a href="http://sale.writemall.com/i" target="_blank">
              <img
                :src="require('assets/images/marketing.png')"
                alt=""
              />营销管理</a
            >
          </div>
        </div>
        <div class="progress">
          <div class="progress-title">
            <div class="left-text">智能网盘</div>
            <div class="right-text">
              <span class="right-size"
                >{{ userInfoList.usedSpace }} /
                {{ userInfoList.totalSpace }}</span
              >
              <span v-if="userInfoList.isvip == 3"></span>
              <!-- <span class="right-btn" @click="handleVip" v-else>扩容</span> -->
            </div>
          </div>
          <el-progress
            :show-text="false"
            :stroke-width="10"
            :percentage="calculation()"
          ></el-progress>
        </div>
        <div class="vipInfo-bigBox">
          <div class="vipInfo" @click="handleVip">
            <div class="vipInfo-name">
              <!-- <i class="vipInfo-name-icon"></i> -->
              <div class="vipInfo-name-text" v-if="userInfoList.isvip == 1">
                普通会员
              </div>
              <div
                class="vipInfo-name-text"
                v-else-if="userInfoList.isvip == 2"
              >
                VIP会员
              </div>
              <div class="vipInfo-name-text" v-else>SVIP会员</div>
            </div>
            <div class="vipInfo-line"></div>
            <div class="vipInfo-time" v-if="userInfoList.isvip == 1">
              开通VIP/SVIP会员享更多权益
            </div>
            <div class="vipInfo-time" v-else-if="userInfoList.isvip == 2">
              {{ userInfoList.time }}到期 >
            </div>
            <div class="vipInfo-time" v-else>{{ userInfoList.time }}到期 ></div>
          </div>
        </div>
        <div class="navGroup">
          <div class="navGroup-item" @click="handleAccount">
            <i class="navGroup-item-account"></i>
            <div class="navGroup-item-text">账户记录</div>
          </div>
          <div class="navGroup-item" @click="handleCard">
            <i class="navGroup-item-card"></i>
            <div class="navGroup-item-text">体验卡</div>
          </div>
          <div class="navGroup-item" @click="IsmessageDialog">
            <i class="navGroup-item-horn"></i>
            <div class="navGroup-item-text">我的消息</div>
          </div>
          <div class="navGroup-item" @click="handeleSetup">
            <i class="navGroup-item-setUp"></i>
            <div class="navGroup-item-text">设置中心</div>
          </div>
        </div>
        <div class="title">
          <div @click="updata" class="title-text">账号与资料</div>
          <div class="title-btn" @click="loginOut">退出</div>
        </div>
        <div v-if="userInfoList.isvip == 1"></div>
        <div class="vip" v-else-if="userInfoList.isvip == 2">VIP</div>
        <div class="svip" v-else>SVIP</div>
      </el-dialog>

      <!-- 消息中心弹窗 -->
      <el-dialog
        :modal="false"
        top="0"
        :close-on-click-modal="false"
        :visible.sync="messageDialog"
        class="message_dialog"
        title="消息中心"
      >
        <el-tabs class="message_tabs" tab-position="left" style="height: 700px">
          <el-tab-pane>
            <div @click="getMessageList(1)" class="message_item" slot="label">
              <div class="img_box">
                <img src="../../assets/images/comments.png" alt="" /> 系统消息
              </div>
              <div v-if="unreadFindSysMsg.systemMsgTotal != 0" class="sum">
                {{ unreadFindSysMsg.systemMsgTotal }}
              </div>
            </div>
            <div class="comments">
              <div v-if="systemMessageList.length !== 0">
                <div class="comments_top">
                  <div class="text">系统消息</div>
                  <div
                    :class="isShowoperation ? 'operation' : 'red'"
                    @click="isShowoperation = !isShowoperation"
                  >
                    {{ !isShowoperation ? "编辑" : "取消" }}
                  </div>
                </div>
                <el-checkbox-group
                  v-model="check"
                  @change="handleCheckedCitiesChange"
                >
                  <div
                    class="comments_center"
                    v-for="(item, index) in systemMessageList"
                    :key="index"
                  >
                    <div class="center_box_top">
                      <div class="left">
                        <div class="choose">
                          <el-checkbox
                            :label="item.id"
                            v-show="isShowoperation"
                          >
                          </el-checkbox>

                          <div class="choose_box">
                            <div class="title">{{ item.title }}</div>
                            <div class="rigth">{{ item.createdate }}</div>
                          </div>
                          <div class="center_box_bottom">
                            {{ item.content }}
                            <!-- <span class="color_red">立即续费>></span> -->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </el-checkbox-group>
              </div>
              <div class="img" v-else>
                <img src="../../assets/images/no-message.png" alt="" />
                暂无消息
              </div>
            </div>
          </el-tab-pane>

          <el-tab-pane>
            <div class="message_item" @click="getMessageList(2)" slot="label">
              <div class="img_box">
                <img src="../../assets/images/system-message.png" alt="" /> 评论
              </div>
              <div v-show="unreadFindSysMsg.commentMsgTotal != 0" class="sum">
                {{ unreadFindSysMsg.commentMsgTotal }}
              </div>
            </div>
            <div class="comments">
              <div v-if="commentsMessageList.length !== 0">
                <div
                  class="message_item_box"
                  v-for="(item, index) in commentsMessageList"
                  :key="index"
                >
                  <div class="message_title">
                    {{ item.title }}
                  </div>

                  <div class="message_content">
                    {{ item.content }}
                  </div>
                </div>
              </div>
              <div class="img" v-else>
                <img src="../../assets/images/no-message.png" alt="" />
                暂无评论
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane>
            <div class="message_item" @click="getMessageList(3)" slot="label">
              <div class="img_box">
                <img src="../../assets/images/praise.png" alt="" /> 赞
              </div>
              <div v-show="unreadFindSysMsg.praiseMsgTotal != 0" class="sum">
                {{ unreadFindSysMsg.praiseMsgTotal }}
              </div>
            </div>
            <div v-if="praiseMessageList.length !== 0">
              <div class="comments">
                <div class="comments_top">
                  <div class="text">赞</div>
                </div>

                <div
                  class="comments_center"
                  v-for="(item, index) in praiseMessageList"
                  :key="index"
                >
                  <div class="praise_top">
                    <img
                      :src="
                        item.headImg
                          ? item.headImg
                          : require('assets/images/user.png')
                      "
                      alt=""
                    />
                    {{ item.userName }}
                  </div>
                  <div class="praise_center">
                    点赞了
                    <img src="../../assets/images/fabulous-red1.png" alt="" />
                  </div>
                  <div class="praise_bottom">
                    <div class="title">
                      {{ item.title }}
                    </div>
                    <div class="p">
                      {{ item.content }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="imgclass" v-else>
              <img
                class="img"
                src="../../assets/images/no-message.png"
                alt=""
              />
              暂无消息
            </div>
          </el-tab-pane>

          <el-tab-pane v-for="(item, index) in userMessageList" :key="index">
            <div
              class="message_item user_box"
              @click="getMessageList(4, item.userId)"
              slot="label"
            >
              <div class="img_box user_img">
                <img
                  :src="
                    item.headImg
                      ? item.headImg
                      : require('assets/images/user.png')
                  "
                  alt=""
                />
              </div>
              <div class="user_info">
                <div class="user_name">
                  <span class="name">{{ item.userName }}</span>
                  <span>{{ getTimer(item.sendTime) }}</span>
                </div>
                <div class="user_content">
                  <div class="content">
                    {{ item.content }}
                  </div>
                  <div v-show="item.isreadNum != 0" class="sum">
                    {{ item.isreadNum }}
                  </div>
                </div>
              </div>
            </div>

            <div class="user_message">
              <div class="user">
                {{ item.userName }}
              </div>
              <div class="message">
                <div class="date" v-show="usermessage.length != 1">
                  {{ getTimer(item.sendTime) }}
                </div>

                <div v-for="(item, index) in usermessage" :key="index">
                  <div v-show="item.is_show_time" class="date">
                    {{ getTimer(item.createdate) }}
                  </div>
                  <div v-show="item.userid == userId" class="left_mesage">
                    <img
                      :src="
                        item.headImg
                          ? item.headImg
                          : require('assets/images/user.png')
                      "
                      alt=""
                    />
                    <div class="text">{{ item.content }}</div>
                  </div>

                  <div class="rigth_mesage" v-show="item.userid != userId">
                    <div class="text">{{ item.content }}</div>
                    <img
                      :src="
                        item.headImg
                          ? item.headImg
                          : require('assets/images/user.png')
                      "
                      alt=""
                    />
                  </div>
                </div>
              </div>
              <div class="reply">
                <el-input
                  type="textarea"
                  :rows="7"
                  v-model="textarea"
                  maxlength="500"
                  show-word-limit
                  @keyup.enter.native="addmgs()"
                >
                </el-input>

                <el-button @click="addmgs(item.userId)">发送</el-button>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>

        <div class="comments_bottom" v-show="isShowoperation">
          <div class="select_all">
            <el-checkbox
              :indeterminate="isIndeterminate"
              v-model="checkAll"
              @change="handleCheckAllChange"
              >全选</el-checkbox
            >
          </div>
          <div class="delete" @click="delBatchMessage">删除</div>
        </div>
      </el-dialog>
    </div>
    <userInfoDialog ref="infoDialog"></userInfoDialog>

    <registrationDialog
      ref="registrationDialog"
      @registerClick="registerClick"
    />

    <registrationDialog1 ref="registrationDialog1" />
    <registrationDialog2 ref="registrationDialog2" />
  </div>
</template>
<script>
import registrationDialog from "components/Exclusive-registration/registrationDialog.vue";
import registrationDialog1 from "components/Exclusive-registration/registrationDialog1.vue";
import registrationDialog2 from "components/Exclusive-registration/registrationDialog2.vue";
import Cookies from "js-cookie";
import dayjs from "dayjs";
import { getToken } from "../../utils/auth.js";
import userInfoDialog from "components/header/userInfoDialog";
import {
  update,
  register,
  passLogin,
  codeLogin,
  getuserInfo,
  passwordLogin,
  userInfoUpd,
  getMobileAchCaptchas,
} from "api/service";
import {
  FindSysMsg,
  findMsgInfoList,
  FindFriendMsg,
  Addmessage,
  Readmessage,
  DelBatchMessage,
} from "api/header";
import {
  upload,
  getMobileAchCaptcha,
  checkMobileAchCaptchas,
} from "api/common";
export default {
  name: "Header",
  data() {
    return {
      isUser_info: "",
      //验证码
      verif_up: "",
      //验证码ID
      x_verif_code: "",
      //发送验证码计时
      x_verif_seconds: 60,
      //出生日期
      date: "",
      //修改信息弹窗
      upFlag: false,
      //下载图标
      wflag: false,
      iflag: false,
      aflag: false,
      //下载弹窗
      downflag: false,
      //【忘记密码】弹窗
      forgot_flag: false,
      //手机号
      phone_forgot: "",
      //密码
      password_forgot: "",
      //验证码
      verif_forgot: "",
      //发送验证码计时
      f_verif_seconds: 60,
      //验证码id
      f_verif_code: "",
      //显示密码
      f_show_pass: false,
      //【登录】密码登录输入手机号码
      phone_number_mode1: "",
      //验证码登录输入手机号码
      phone_number_mode2: "",
      //密码
      password: "",
      //验证码
      verif: "",
      //登录类型
      loginType: 1,
      //显示密码
      show_pass: false,
      //记住密码
      remenber_password: false,
      //验证码ID
      verif_code: "",
      //发送验证码计时
      verif_seconds: 60,
      //timer
      timer: null,
      //登录弹窗
      login_flag: false,
      //微信绑定手机号
      phone_wx: "",
      //微信验证码
      verif_wx: "",
      //【注册】手机号码
      phone_number: "",
      //密码
      R_password: "",
      //重复密码
      password_again: "",
      //显示密码
      R_show_pass: false,
      //显示重复密码
      show_pass_again: false,
      //验证码
      R_verif: "",
      //验证码ID
      R_verif_code: "",
      //发送验证码计时
      R_verif_seconds: 60,
      //推荐码(选填)
      recommendation_code: "",
      //注册弹窗
      register_flag: false,
      showdale: false,
      navLang: [
        {
          title: "溯源",
          path: "/traceTheSource",
        },
        {
          title: "原创存证",
          path: "/store",
        },
        {
          title: "智能网盘",
          path: "/disk",
        },
        {
          title: "版权收益",
          path: "/copyrightIncome",
        },
        {
          title: "版权服务",
          path: "/copyrightService",
        },
        {
          title: "关于我们",
          path: "/aboutus",
        },
      ],
      defaultHeadImg: require("assets/images/user.png"), //默认头像
      isLogin: false, //是否登录
      userInfo: false, //用户信息弹窗
      diskRario: 0,
      userInfoList: {
        isvip: 1, //1是普通会员 2是vip 3是svip
        imgSrc: require("assets/images/user.png"),
        name: "喝奶茶的恐龙",
        id: 2258697,
        totalSpace: "1GB",
        usedSpace: "0KB",
        time: "2020-02-02",
        character: "1.6W",
      },
      // 消息中心弹窗
      messageDialog: false,
      // 编辑
      isShowoperation: false,
      // 要删除的信息arr
      check: [],
      // 全选
      checkAll: false,
      // 全选状态
      isIndeterminate: false,
      // 系统消息
      systemMessageList: [],
      // 赞消息
      praiseMessageList: [],
      // 评论消息
      commentsMessageList: [],
      // 查询左侧用户未读消息
      userMessageList: [],
      // 用户消息
      usermessage: [],
      // 用户输入消息
      textarea: "",
      // 用户ID
      userId: this.$store.state.user_info?.id,
      //修改信息男女标识
      gender: null,
      //个性签名
      autograph: "",
      //验证手机号
      phone: "",
      //头像地址
      url: "",
      //修改手机是否正确
      phoneFlag: false,
      //个人简介
      introduction: "",
      //颜色标识
      yes: false,
      //昵称标识
      nameflag: true,
      //触摸头像
      enterAvatar: false,
      // 未读消息
      unreadFindSysMsg: "",
      // 路由name
      routeName: "",
      info: "",
    };
  },
  components: {
    userInfoDialog,
    registrationDialog,
    registrationDialog1,
    registrationDialog2,
  },
  computed: {
    isEnterDisk() {
      return this.$route.matched[0] && this.$route.matched[0].name === "disk";
    },
  },

  mounted() {
    // if (!getToken("auth_token")) {
    //   this.login_flag = true;
    // }
    // this.$bus.$on("registerClick1", function (val) {
    //   this.showdale = val;
    // });
    window.addEventListener("scroll", this.scrollHandle, true);
  },
  methods: {
    // 专属优惠弹窗注册被点击
    registerClick(val) {
      this.register_flag = val;
    },

    // 记住密码
    rememberClick(val) {
      if (val) {
        let infoobj = {
          phone: this.phone_number_mode1,
          password: this.password,
          remenber: val,
        };
        window.localStorage.setItem("infoobj", JSON.stringify(infoobj));
      } else {
        window.localStorage.setItem("infoobj", "");
      }
    },

    scrollHandle() {
      this.userInfo = false;
    },
    // 图片容错
    imgError(val) {
      val.imgSrc = require("assets/images/user.png");
    },

    Re_name() {
      //  手机号验证
      if (this.phone_number == "" || this.phone_number.length != 11) {
        this.$message({
          type: "error",
          message: "请正确输入手机号！",
        });
        return;
      }
    },
    // 批量删除
    async delBatchMessage() {
      let form = new FormData();
      form.append("ids", this.check);

      const res = await DelBatchMessage(form);
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.getMessageList(1);
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    // 读消息
    async readmessage(id, type, sendid) {
      const data = {
        id,
        type,
        sendid,
      };

      const res = await Readmessage(data);
    },
    // 发送消息
    async addmgs(id) {
      const data = {
        userid: id,
        content: this.textarea,
        title: "试试",
        type: 4,
      };
      if (this.textarea == "") {
        return this.$message({
          type: "error",
          message: "消息不能为空",
        });
      }
      const res = await Addmessage(data);
      this.textarea == "";
      this.getMessageList(4, id);
      this.findFriendMsg();
    },
    /*格式化日期*/
    getTimer(stringTime) {
      var minute = 1000 * 60;
      var hour = minute * 60;
      var day = hour * 24;
      var week = day * 7;
      var month = day * 30;
      var time1 = new Date().getTime(); //当前的时间戳
      var time2 = Date.parse(new Date(stringTime)); //指定时间的时间戳
      var time = time1 - time2;
      var result = null;
      if (time < 0) {
        alert("设置的时间不能早于当前时间！");
      } else if (time / month >= 1) {
        result = parseInt(time / month) + "月前";
      } else if (time / week >= 1) {
        result = parseInt(time / week) + "周前";
      } else if (time / day >= 1) {
        result = parseInt(time / day) + "天前";
      } else if (time / hour >= 1) {
        result = parseInt(time / hour) + "小时前";
      } else if (time / minute >= 1) {
        result = parseInt(time / minute) + "分钟前";
      } else {
        result = "刚刚";
      }
      return result;
    },
    //循环处理时间
    /**
     * 参数
     * arr：数据数组
     * key：数组中对象的时间key键。
     * 新增属性
     * show_time_type：时间的类型
     * show_time：页面展示输出的时间
     * is_show_time：间隔上个时间是否大于5分钟，大于则显示当前时间，反之。
     **/
    checkShowRule(arr, key) {
      var newArr = arr.map((item, index, array) => {
        var obj = this.toggleTime(item[key]);
        item["show_time_type"] = obj.type;
        item["show_time"] = obj.time;
        if (index > 0) {
          item["is_show_time"] = this.compareTimeInterval(
            array[index - 1][key],
            array[index][key]
          );
        } else {
          item["is_show_time"] = true;
        }
        return item;
      });
      return newArr;
    },

    //根据不同时间的消息，输出不同的时间格式
    toggleTime(date) {
      var time;
      var type = this.getDateDiff(date);
      //1：新消息，2：当天消息,3：昨天消息，4：今年消息，5：其他消息
      if (type == 1) {
        time = "以下为最新消息"; //新消息，不显示时间，但是要显示"以下为最新消息"
      } else if (type == 2) {
        time = dayjs(date).format("H:mm"); //当天消息，显示：10:22
      } else if (type == 3) {
        time = dayjs(date).format("昨天 H:mm"); //昨天消息，显示：昨天 20:41
      } else if (type == 4) {
        time = dayjs(date)
          .format("M月D日 AH:mm")
          .replace("AM", "上午")
          .replace("PM", "下午"); //今年消息，上午下午，显示：3月17日 下午16:45
      } else if (type == 5) {
        time = dayjs(date)
          .format("YYYY年M月D日 AH:mm")
          .replace("AM", "上午")
          .replace("PM", "下午"); //其他消息，上午下午，显示：2020年11月2日 下午15:17
      }
      return {
        time: time,
        type: type,
      };
    },
    //判断消息类型
    getDateDiff(date) {
      var nowDate = dayjs(new Date()); //当前时间
      var oldDate = dayjs(new Date(date)); //参数时间
      var result;
      if (nowDate.year() - oldDate.year() >= 1) {
        result = 5;
      } else if (
        nowDate.month() - oldDate.month() >= 1 ||
        nowDate.date() - oldDate.date() >= 2
      ) {
        result = 4;
      } else if (nowDate.date() - oldDate.date() >= 1) {
        result = 3;
      } else if (
        nowDate.hour() - oldDate.hour() >= 1 ||
        nowDate.minute() - oldDate.minute() >= 5
      ) {
        result = 2;
      } else {
        result = 1;
      }
      return result;
    },
    //判断两个时间差是否大于5分钟
    compareTimeInterval(t1, t2) {
      return dayjs(t2) - dayjs(t1) >= 300000 ? true : false;
    },
    // 打开消息弹窗
    IsmessageDialog() {
      this.messageDialog = true;
      this.findSysMsg();
      this.getMessageList(1);
      this.findFriendMsg();
    },
    // 获取消息列表
    async getMessageList(type, userId) {
      const data = {
        userId,
        page: 1,
        limit: 100,
        type,
      };
      this.isShowoperation = false;
      this.textarea = "";
      const res = await findMsgInfoList(data);
      if (res.code == 200) {
        if (type == 1) {
          this.systemMessageList = res.data.data;
          this.readmessage("", 0);
        } else if (type == 2) {
          this.commentsMessageList = res.data.data;
          this.readmessage("", 2);
        } else if (type == 3) {
          this.praiseMessageList = res.data.data;
          this.readmessage("", 3);
        } else {
          this.readmessage("", 4, userId);
          this.usermessage = this.checkShowRule(
            res.data.data,
            "createdate"
          ).reverse();
        }
        this.findSysMsg();
        this.findFriendMsg();
      }
    },
    // 查询左侧用户未读消息
    async findFriendMsg() {
      const data = {
        page: 1,
        limit: 100,
      };

      const res = await FindFriendMsg(data);

      this.userMessageList = res.data.data;
    },

    handleCheckedCitiesChange(value) {
      let checkedCount = value.length;
      this.checkAll = checkedCount === this.systemMessageList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.systemMessageList.length;
    },
    // 全选
    handleCheckAllChange(val) {
      let arr = this.systemMessageList.map((item) => {
        return item.id;
      });
      this.check = val ? arr : [];
      this.isIndeterminate = false;
    },
    // 查询未读消息
    async findSysMsg() {
      const res = await FindSysMsg();

      this.unreadFindSysMsg = res.data;
    },
    lazy() {
      //正则验证
      var reg = /^[\u4e00-\u9fa5_a-zA-Z0-9]{3,7}$/;
      var regExp = new RegExp(reg);
      if (regExp.test(this.userInfoList.nickName)) {
        this.yes = false;
        this.nameflag = true;
      } else {
        this.yes = true;
        this.nameflag = false;
      }
    },
    //关闭修改框
    upclose() {
      this.verif_up = "";
      this.upFlag = false;
      this.yes = false;
      this.userInfoList.mobile = this.phone;
    },
    //发送验证码
    x_sendVerif() {
      if (this.userInfoList.mobile == "") {
        this.$message({
          type: "error",
          message: "请输入手机号！",
        });
        return;
      }
      this.x_verif_seconds--;
      let data = {
        type: 1,
        mobile: this.userInfoList.mobile,
      };
      getMobileAchCaptcha(data).then((res) => {
        if (res.code == 200) {
          this.x_verif_code = res.data.codeId;
          this.x_setVerifTime();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //发送验证码计时
    x_setVerifTime() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.x_verif_seconds > 0) {
            this.x_verif_seconds--;
          } else {
            clearInterval(this.timer);
            this.x_verif_seconds = 60;
            this.timer = null;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
      //setTimeout(this.checkMobileAchCaptcha(),60000)
    },
    //验证验证码
    async checkMobileAchCaptcha() {
      let data = {
        mobile: this.userInfoList.mobile,
        codeId: this.x_verif_code,
        code: this.verif_up,
      };
      let res = await checkMobileAchCaptchas(data);
      if (res.code == 200) {
        this.phoneFlag = true;
      }
    },
    //更改信息
    async upData() {
      let data = {
        nickName: this.userInfoList.nickName,
        mobile: this.userInfoList.mobile,
        headImg: this.url,
        sex: this.gender,
        birthday: this.userInfoList.birthday,
        signature: this.userInfoList.signature,
        introduction: this.userInfoList.introduction,
      };
      let res = await userInfoUpd(data);
      if (res.code == 200) {
        this.upFlag = false;
        this.$message.success("保存成功");
        this.date = "";
        this.gender = 3;
        this.autograph = "";
        this.introduction = "";
        this.verif_up = "";
        this.userInfoList.mobile = this.phone;
        this.getUserinfo();
      } else {
        this.$message.error(res.message);
      }
    },
    //修改信息
    async keep() {
      if (!this.nameflag) {
        this.$message.error("昵称格式不正确");
        return;
      }
      if (this.url == "") {
        this.url = this.userInfoList.imgSrc;
      }
      if (this.userInfoList.mobile != this.phone) {
        let data = {
          mobile: this.userInfoList.mobile,
          codeId: this.x_verif_code,
          code: this.verif_up,
        };
        checkMobileAchCaptchas(data).then((res) => {
          if (res.code == 200) {
            this.phone = this.userInfoList.mobile;
            this.updata();
          } else {
            this.$message.error("手机号或验证码错误");
            return;
          }
        });
      } else {
        this.updata();
      }
      this.upData();
    },
    //上传头像
    async getup() {
      let file = this.$refs["file"].files[0];
      let form = new FormData();
      form.append("file", file);
      let res = await upload(form);
      if (res.code == 200) {
        this.url = this.$store.state.onlineBasePath + res.data;
        this.userInfoList.imgSrc = this.url;
      }
    },
    //点击头像
    handleClickAvatar() {
      this.$refs["file"].click();
    },
    //修改信息弹窗
    updata() {
      this.upFlag = true;
      this.userInfo = false;
      this.gender = String(this.userInfoList.sex);
    },
    //点击下载
    go_down() {
      // this.downflag = true;
      this.$router.push({
        path: "/downloadGuide",
      });
    },
    // 反馈
    go_feedback() {
      this.$router.push({
        name: "Feedback",
      });
    },
    // 跳转
    clickIos() {
      if (event.target.outerText == "Ios") {
        window.open("https://www.pgyer.com/VBDk");
      }
      if (event.target.outerText == "Android") {
        window.open("https://www.pgyer.com/K2BH");
      }
    },
    //鼠标移入
    enter() {
      if (event.target.outerText == "Windows") {
        this.wflag = true;
      }
      if (event.target.outerText == "Ios") {
        this.iflag = true;
      }
      if (event.target.outerText == "Android") {
        this.aflag = true;
      }
    },
    //鼠标移出
    leaver() {
      if (event.target.outerText == "Windows") {
        this.wflag = false;
      }
      if (event.target.outerText == "Ios") {
        this.iflag = false;
      }
      if (event.target.outerText == "Android") {
        this.aflag = false;
      }
    },

    //关闭忘记密码弹窗
    forgotCancel() {
      this.forgot_flag = false;
    },
    //发送验证码
    f_sendVerif() {
      if (this.phone_forgot == "") {
        this.$message({
          type: "error",
          message: "请输入手机号！",
        });
        return;
      }
      if (this.password_forgot == "") {
        this.$message({
          type: "error",
          message: "请输入密码!",
        });
        return;
      }
      if (this.password_forgot.length < 6) {
        this.$message({
          type: "error",
          message: "密码小于6位!",
        });
        return;
      }
      let data = {
        type: 2,
        mobile: this.phone_forgot,
      };
      getMobileAchCaptchas(data).then((res) => {
        if (res.code == 200) {
          this.f_verif_code = res.data.codeId;
          this.f_setVerifTime();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //发送验证码计时
    f_setVerifTime() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.f_verif_seconds > 0) {
            this.f_verif_seconds--;
          } else {
            clearInterval(this.timer);
            this.f_verif_seconds = 60;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
    },
    //修改密码
    async toUpdate() {
      //  手机号验证
      if (this.phone_forgot == "" || this.phone_forgot.length != 11) {
        this.$message({
          type: "error",
          message: "请正确输入手机号！",
        });
        return;
      }
      // 密码验证
      if (this.password_forgot == "") {
        this.$message({
          type: "error",
          message: "请输入密码！",
        });
        return;
      } else if (this.password_forgot.length < 6) {
        this.$message({
          type: "error",
          message: "密码不能少于6位",
        });
        return;
      }
      // 验证码验证
      if (this.verif_forgot == "") {
        this.$message({
          type: "error",
          message: "验证码不能为空！",
        });
        return;
      }
      const data = {
        mobile: this.phone_forgot,
        codeId: this.f_verif_code,
        code: this.verif_forgot,
        newPassword: this.password_forgot,
      };
      const res = await update(data);
      if (res.code === 200) {
        this.$message({
          type: "success",
          message: "修改成功！",
        });
        this.login_flag = true;
        this.forgot_flag = false;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //判断登陆状态
    iflogin() {
      if (!this.isLogin) {
        this.register_flag = false;
        this.login_flag = false;
      }
    },
    //【登录】点击取消登录
    loginCancel() {
      this.loginType = 1;
      this.phone_number_mode1 = "";
      this.phone_number_mode2 = "";
      this.password = "";
      this.verif = "";
      this.login_flag = false;
    },
    //去注册
    toRegister() {
      this.login_flag = false;
      this.register_flag = true;
    },
    //忘记密码
    toForgot() {
      this.login_flag = false;
      this.forgot_flag = true;
    },
    //手机验证
    validatePhone(phone) {
      let reg = /^1[0-9]{10}$/;
      if (!phone) {
        this.$message({
          type: "error",
          message: "请输入手机号!",
        });
        return false;
      }
      if (!reg.test(phone)) {
        this.$message({
          type: "error",
          message: "请输入正确的手机号!",
        });
        return false;
      }
      return true;
    },
    //密码登录
    pwdLogin() {
      this.rememberClick(this.remenber_password);
      if (!this.validatePhone(this.phone_number_mode1)) return;
      if (!this.password) {
        return this.$message({
          type: "error",
          message: "请输入密码!",
        });
      }
      const data = {
        mobile: this.phone_number_mode1,
        password: this.password,
      };
      passwordLogin(data)
        .then((res) => {
          let data = res;
          data = JSON.stringify(data);
          //保存token
          Cookies.set("auth_token", data, {
            expires: 7,
            path: "/",
            domain: ".antiplagiarize.com",
          });
          this.$message({
            type: "success",
            message: "登录成功!",
          });
          this.login_flag = false;
          this.getUserinfo();

          if (this.routeName == "TraceTheSource") {
            return;
          } else if (this.routeName == "StoreRightBox") {
            return;
          }

          window.location.reload(true);
        })
        .catch(() => {
          this.$message.error("手机号或密码错误");
        });
    },
    //发送验证码
    sendVerif() {
      if (!this.validatePhone(this.phone_number_mode2)) return;
      const data = {
        type: 5,
        mobile: this.phone_number_mode2,
      };
      getMobileAchCaptchas(data).then((res) => {
        if (res.code == 200) {
          this.verif_code = res.data.codeId;
          this.setVerifTime();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //发送验证码计时
    setVerifTime() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.verif_seconds > 0) {
            this.verif_seconds--;
          } else {
            clearInterval(this.timer);
            this.verif_seconds = 60;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
    },
    //验证码登录
    async verifLogin() {
      if (!this.validatePhone(this.phone_number_mode2)) return;

      if (!this.verif) {
        return this.$message({
          type: "error",
          message: "请输入验证码!",
        });
      }
      const data = {
        mobile: this.phone_number_mode2,
        code: this.verif,
        id: this.verif_code,
      };

      try {
        let res = await codeLogin(data);
        if (res) {
          let data = res;
          data = JSON.stringify(data);
          //保存token
          Cookies.set("auth_token", data, {
            expires: 7,
            path: "/",
            domain: ".antiplagiarize.com",
          });
          this.$message({
            type: "success",
            message: "登录成功!",
          });
          this.getUserinfo();
          this.login_flag = false;

          if (this.routeName == "TraceTheSource") {
            return;
          } else if (this.routeName == "StoreRightBox") {
            return;
          }
          window.location.reload(true);
        }
      } catch (error) {
        this.$message({
          type: "error",
          message: "验证码错误!",
        });
      }
    },
    handleEnter(e) {
      if (e.keyCode === 13) {
        if (this.loginType == 1) {
          this.pwdLogin();
        }
        if (this.loginType == 2) {
          this.verifLogin();
        }
      }
    },
    //【注册】点击取消注册
    registerCancel() {
      this.register_flag = false;
    },
    async register() {
      //  手机号验证
      if (this.phone_number == "" || this.phone_number.length != 11) {
        this.$message({
          type: "error",
          message: "请正确输入手机号！",
        });
        return;
      }
      // 密码验证
      if (this.R_password == "" || this.password_again == "") {
        this.$message({
          type: "error",
          message: "请输入密码！",
        });
        return;
      } else if (this.R_password != this.password_again) {
        this.$message({
          type: "error",
          message: "两次输入的密码不同！",
        });
        return;
      } else if (this.R_password.length < 6) {
        this.$message({
          type: "error",
          message: "密码不能少于6位",
        });
        return;
      }
      // 验证码验证
      if (this.R_verif == "") {
        this.$message({
          type: "error",
          message: "验证码不能为空！",
        });
        return;
      }
      //参数
      const data = {
        mobile: this.phone_number,
        password: this.R_password,
        codeId: this.R_verif_code,
        code: this.R_verif,
        inviteCode: this.recommendation_code,
      };
      //发送请求
      const res = await register(data);
      //是否注册成功
      if (res.code === 200) {
        //参数
        const content = {
          mobile: this.phone_number,
          password: this.R_password,
        };
        //密码登录
        passwordLogin(content).then((respon) => {
          let date = respon;
          date = JSON.stringify(date);
          //保存token
          Cookies.set("auth_token", date, {
            expires: 7,
            path: "/",
            domain: ".writemall.com",
          });
          this.$message({
            type: "success",
            message: "注册成功！获赠5000字符！",
          });
          this.register_flag = false;
          this.getUserinfo();

          if (this.routeName == "TraceTheSource") {
            return;
          } else if (this.routeName == "StoreRightBox") {
            return;
          }

          window.location.reload(true);
        });
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    //发送验证码
    R_sendVerif() {
      if (this.phone_number == "") {
        this.$message({
          type: "error",
          message: "请输入手机号！",
        });
        return;
      }
      if (this.R_password == "" || this.password_again == "") {
        this.$message({
          type: "error",
          message: "请输入密码!",
        });
        return;
      }

      if (this.R_password < 6 || this.password_again < 6) {
        this.$message({
          type: "error",
          message: "密码小于6位!",
        });
        return;
      }
      let data = {
        type: 3,
        mobile: this.phone_number,
      };
      getMobileAchCaptchas(data).then((res) => {
        if (res.code == 200) {
          this.R_verif_code = res.data.codeId;
          this.R_setVerifTime();
        } else {
          this.$message({
            type: "error",
            message: res.message,
          });
        }
      });
    },
    //发送验证码计时
    R_setVerifTime() {
      if (!this.timer) {
        this.timer = setInterval(() => {
          if (this.R_verif_seconds > 0) {
            this.R_verif_seconds--;
          } else {
            clearInterval(this.timer);
            this.R_verif_seconds = 60;
          }
        }, 1000);
      } else {
        clearInterval(this.timer);
      }
    },

    //立即登录
    toLogino() {
      let infoobj;
      try {
        infoobj = JSON.parse(window.localStorage.getItem("infoobj"));
        if (infoobj) {
          this.phone_number_mode1 = infoobj.phone;
          this.password = infoobj.password;
          this.remenber_password = true;
        }
      } catch (error) {
        this.phone_number_mode1 = "";
        this.password = "";
        this.remenber_password = false;
      }
      this.loginType = 1;
      this.phone_number_mode2 = "";
      this.verif = "";
      this.register_flag = false;
      this.login_flag = true;
      this.forgot_flag = false;
    },
    handleUserInfo() {
      this.userInfo = true;
      this.getUserinfo();
    },
    //退出登录
    loginOut() {
      this.$confirm("此操作将退出当前登录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.isLogin = false;
          this.userInfo = false;
          //Cookies.remove("auth_token");
          //清除token
          Cookies.set("auth_token", "", {
            expires: -1,
            path: "/",
            domain: ".antiplagiarize.com",
          });
          //清除用户信息
          // localStorage.clear();
          this.$store.commit("SET_USER_INFO", {
            userInfo: null,
          });
          this.$store.commit("MUTATE_REAL_NAME",null);
          window.location.reload(true);
          this.isUser_info = JSON.parse(window.localStorage.getItem("vuex"))
            ?.user_info
            ? JSON.parse(window.localStorage.getItem("vuex"))?.user_info
            : "";
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
    // 计算百分比Authorization
    calculation() {
      // let num = this.userInfoList.usedSpace/this.userInfoList.totalSpace * 100
      let num = this.diskRario;
      return num;
    },

    //获取用户信息
    async getUserinfo() {
      try {
        const res = await getuserInfo();
        if (res.code === 200) {
          let userData = res.data;
          this.info = res.data;
          // localStorage.setItem("user_info", JSON.stringify(res.data));
          this.$store.commit({
            type: "SET_USER_INFO",
            userInfo: userData,
          });
          this.$store.dispatch("CHECK_REAL_NAME");
          this.isLogin = true;
          if (userData.memberlevel == 0 || userData.memberlevel == 1) {
            this.userInfoList.isvip = 1;
          } else if (userData.memberlevel == 2) {
            this.userInfoList.isvip = 2;
          } else if (userData.memberlevel == 3) {
            this.userInfoList.isvip = 3;
          }
          this.userInfoList.id = userData.number;
          this.userInfoList.time = userData.expirationDate;
          this.userInfoList.name = userData.username;
          this.userInfoList.character = userData.character;
          this.userInfoList.usedSpace = userData.usedSpace;
          this.userInfoList.totalSpace = userData.totalSpace;
          this.diskRario = userData.diskRario;
          this.userInfoList.imgSrc = userData.headImg;
          this.userInfoList.nickName = userData.nickName;
          this.userInfoList.mobile = userData.mobile;
          this.userInfoList.sex = userData.sex;
          this.userInfoList.introduction = userData.introduction;
          this.userInfoList.signature = userData.signature;
          this.userInfoList.birthday = userData.birthday;

          this.isUser_info = JSON.parse(window.localStorage.getItem("vuex"))
            ?.user_info
            ? JSON.parse(window.localStorage.getItem("vuex"))?.user_info
            : "";
        }
      } catch (e) {
        this.$store.commit("SET_USER_INFO", {
          userInfo: null,
        });
        this.isUser_info = JSON.parse(window.localStorage.getItem("vuex"))
          ?.user_info
          ? JSON.parse(window.localStorage.getItem("vuex"))?.user_info
          : "";

        this.$store.commit("MUTATE_REAL_NAME",null);
      }
    },
    //打开账户记录
    handleAccount() {
      this.$router.push({
        path: "/accountRecords",
      });
      this.userInfo = false;
    },
    // 打开体验卡
    handleCard() {
      this.$refs.infoDialog.dialogCard = true;
    },
    // 打开vip弹窗
    handleVip() {
      this.$refs.infoDialog.dialogVisible = true;
    },
    // 打开设置中心
    handeleSetup() {
      this.$refs.infoDialog.dialogsetup = true;
    },
  },
  watch: {
    $route(to, from) {
      this.routeName = to.name;
    },
  },

  created() {
    this.iflogin();
    this.$bus.$on("unAuthorized", () => {
      this.toLogino();
    });
    this.getUserinfo();
  },

  destroyed() {
    this.$bus.$off("unAuthorized");
    window.removeEventListener("scroll", this.scrollHandle, true);
  },
};
</script>

<style lang="scss" scoped>
.ml {
  width: 60px !important;
}
.red {
  color: #ff0000 !important;
}
.bg {
  background: #eff3f7;
}
.mt {
  margin-top: -25px;
}
.means {
  width: 833px;
  height: 616px;
  background: #fff;
  box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
  border-radius: 5px;
  position: fixed;
  top: 50%;
  left: 50%;
  z-index: 999;
  transform: translate(-50%, -50%);
  .upitem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 45px;
    .item-j {
      display: flex;
      justify-content: flex-start;
      flex: 1;
      .introduce {
        margin-top: 5px !important;
      }
      textarea {
        // width: 640px !important;
        flex: 1;
        height: 60px !important;
        background: #f5f6f9;
        border-radius: 10px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        box-sizing: border-box;
        padding: 13px 15px;
        resize: none;
      }
      span {
        height: 14px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
    }
    .item-y {
      display: flex;
      align-items: center;
      align-items: center;
      .span {
        height: 14px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
        margin-left: 8px;
      }
      .send {
        display: flex;
        align-items: center;
        width: 240px;
        height: 40px;
        background: #f5f6f9;
        border-radius: 10px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        .sendcode {
          color: #4588ff;
        }
      }
    }
    .item-g {
      span {
        width: 60px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      label {
        margin-left: 8px !important;
        cursor: pointer;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      .nan {
        margin-left: 20px;
      }
      .nv {
        margin-left: 60px;
      }
    }
    .item {
      .tip {
        height: 12px;
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        margin-left: 80px;
        margin-top: 10px;
      }
      span {
        width: 60px;
        height: 14px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333333;
      }
      input {
        width: 240px;
        height: 40px;
        background: #f5f6f9;
        border-radius: 10px;
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #333;
        box-sizing: border-box;
        padding: 13px 15px;
      }
    }
  }
  .uphead {
    @include flex-center;
    margin: 30px 0 13px 0;
    .wrap {
      position: relative;
      width: 100px;
      height: 100px;
      .upfile {
        display: none;
      }
      .head-img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background: rgba(0, 0, 0, 0.5)
          url("~assets/images/personal-avatar-maskicon.png") 50% 50% no-repeat;
        cursor: pointer;
      }
    }
  }
  .choose-img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: 44px;
    margin-left: 65px;
    opacity: 0.5;
    cursor: pointer;
    margin-bottom: 30px;
  }
  .nav {
    width: 833px;
    height: 34px;
    background: #f4f6f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 10px 12px;
    .title {
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #333333;
    }
    .close {
      width: 10px;
      height: 10px;
      color: #999;
      cursor: pointer;
      margin-top: -10px;
    }
  }
}
.down {
  z-index: 10;
  position: absolute;
  top: 64px;
  right: 170px;
  width: 160px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 8px 13px 0px rgba(155, 157, 170, 0.13);
  border-radius: 0px 0px 8px 8px;

  .span {
    height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
    margin-left: 8px;
  }
  .send {
    display: flex;
    align-items: center;
    width: 240px;
    height: 40px;
    background: #f5f6f9;
    border-radius: 10px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    .sendcode {
      color: #4588ff;
    }
  }
}
.item-g {
  span {
    width: 60px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  label {
    margin-left: 8px !important;
    cursor: pointer;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  .nan {
    margin-left: 20px;
  }
  .nv {
    margin-left: 60px;
  }
}
.item {
  .tip {
    height: 12px;
    font-size: 12px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #999999;
    margin-left: 80px;
    margin-top: 10px;
  }
  span {
    width: 60px;
    height: 14px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333333;
  }
  input {
    width: 240px;
    height: 40px;
    background: #f5f6f9;
    border-radius: 10px;
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: 500;
    color: #333;
    box-sizing: border-box;
    padding: 13px 15px;
  }
}

.uphead {
  @include flex-center;
  margin: 30px 0 13px 0;
  .wrap {
    position: relative;
    width: 100px;
    height: 100px;
    .upfile {
      display: none;
    }
    .head-img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      border-radius: 50%;
      background: rgba(0, 0, 0, 0.5)
        url("~assets/images/personal-avatar-maskicon.png") 50% 50% no-repeat;
      cursor: pointer;
    }
  }
}
.choose-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-top: 44px;
  margin-left: 65px;
  opacity: 0.5;
  cursor: pointer;
  margin-bottom: 30px;
}
.nav {
  width: 833px;
  height: 34px;
  background: #f4f6f7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  padding: 10px 12px;
  .title {
    font-size: 14px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
  }
  .close {
    width: 10px;
    height: 10px;
    color: #999;
    cursor: pointer;
    margin-top: -10px;
  }
}

.down {
  z-index: 10;
  position: absolute;
  top: 64px;
  right: 170px;
  width: 160px;
  height: 120px;
  background: #ffffff;
  box-shadow: 0px 8px 13px 0px rgba(155, 157, 170, 0.13);
  border-radius: 0px 0px 8px 8px;

  .down-item {
    width: 160px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    span {
      margin-left: 17px;
      width: 58px;
      height: 12px;
      font-size: 14px;
      font-family: PingFang SC;
      font-weight: 400;
      color: #333333;
      text-align: left;
    }
  }
}

.wxPhone {
  width: 168px;
  height: 13px;
  font-size: 12px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}

.register-main {
  position: fixed;
  z-index: 20;
  top: 50%;
  left: 50%;
  width: 418px;
  height: 454px;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: Microsoft YaHei;
  transform: translate(-50%, -50%);
  border: 0px solid #818790;
  box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
  border-radius: 5px;

  .login-cancel {
    margin-top: 14px;
    margin-left: 390px;
    width: 10px;
    height: 10px;
    color: #c7cbd3;
    cursor: pointer;
  }

  .register-wrap {
    width: 100%;

    .register-input {
      margin-top: 30px;

      .register-input-wrap {
        display: flex;
        align-items: center;
        margin: 0 auto;
        width: 298px;
        height: 43px;
        background: #f3f5f9;
        border-radius: 22px;

        .register-input-icon {
          margin: 0 18px;
          width: 20px;
          height: 20px;

          img {
            width: 100%;
            height: inherit;
          }
        }

        .register-input-content {
          flex: 1;
          height: 100%;
          padding-right: 18px;

          input {
            border: none;
            width: 100%;
            height: 100%;
            color: #999999;
            background: #f3f5f9;

            &::placeholder {
              font-size: 14px;
              color: #999;
            }
          }
        }

        .register-input-type {
          margin-right: 18px;
          width: 20px;
          height: 20px;
          cursor: pointer;

          img {
            width: 100%;
            height: inherit;
          }
        }

        .register-input-sendcode {
          position: relative;
          margin-right: 15px;
          font-size: 12px;
          font-family: Microsoft YaHei;
          font-weight: 500;
          color: #4588ff;
          line-height: 28px;
          cursor: pointer;

          &::after {
            display: block;
            position: absolute;
            content: " ";
            left: -10px;
            top: 6px;
            width: 1px;
            height: 20px;
            background: #cccccc;
          }
        }
      }

      .register-input-wrap + .register-input-wrap {
        margin-top: 20px;
      }
    }

    .register-footer {
      padding-top: 21px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 298px;
      margin: 0 auto;

      &-tips {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 500;
        color: #999999;

        a {
          margin-left: 5px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 500;
          text-decoration: underline;
          color: #4588ff;
          cursor: pointer;
        }
      }

      &-btn {
        width: 99px;
        height: 40px;
        line-height: 40px;
        font-size: 15px;
        text-align: center;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #ffffff;
        background: #4588ff;
        border-radius: 20px;
        cursor: pointer;

        &:hover {
          transform: translateY(-1px);
          box-shadow: 0 5px 10px #e6e4e4;
        }

        &:active {
          box-shadow: 0 0 10px #e6e4e4;
        }
      }
    }
  }
}

//登录弹窗
.login-cancel {
  margin-top: 14px;
  margin-left: 390px;
  width: 10px;
  height: 10px;
  color: #c7cbd3;
  cursor: pointer;
}

.login-main {
  z-index: 10000;
  position: fixed;
  top: 200px;
  left: 50%;
  width: 418px;
  height: 420px;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  font-family: Microsoft YaHei;
  transform: translatex(-50%);
  border: 0px solid #818790;
  box-shadow: 0px 3px 15px 1px rgba(98, 102, 107, 0.16);
  border-radius: 5px;
}

.login-wrap {
  width: 100%;
  /* height: 100%;
	  box-sizing: border-box; */
  /* height: inherit; */
}

.login-logo {
  width: 70px;
  height: 70px;
  overflow: hidden;
  margin-top: 15px;
  margin-left: 174px;
}

.login-mode {
  width: 100%;
}

.login-mode .login-nav {
  display: none;
}

.login-mode-nav {
  @include noselect;
  display: flex;

  margin-top: 29px;
  width: 100%;
  padding: 0 50px;
  box-sizing: border-box;
}

.login-mode-nav li label {
  margin-right: 10px;
  width: 65px;
  height: 16px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  color: #999999;
}

.login-mode-nav .login-nav-active label {
  position: relative;
  font-weight: bold;
  color: #333333;
}

.login-mode-nav .login-nav-active label::after {
  display: block;
  content: " ";
  position: absolute;
  bottom: -9px;
  left: 50%;
  width: 11px;
  height: 3px;
  background-color: #4588ff;
  transform: translateX(-50%);
}

.login-mode-content {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 110px;
  margin-top: 20px;
}

.login-mode-content .login-mode-content-mode {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  transition: display 1s ease;
  /* background-color: #f14141; */
}

.login-mode-content-mode .login-content-inpwrap {
  display: flex;
  align-items: center;
  width: 298px;
  height: 43px;
  background: #f3f5f9;
  border-radius: 22px;
  overflow: hidden;
}

.login-content-inpwrap-icon {
  margin: 0 18px;
  width: 20px;
  height: 20px;
}

.login-content-inpwrap-icon img {
  width: 100%;
  height: inherit;
}

.login-content-inpwrap-input {
  flex: 1;
  height: 100%;
  padding-right: 18px;
}

.login-content-inpwrap-input input {
  border: none;
  width: 100%;
  height: 100%;
  color: #999999;
  background: #f3f5f9;
}

.login-content-inpwrap-input input::placeholder {
  color: #999999;
}

.login-content-inpwrap-type {
  margin-right: 18px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.login-content-inpwrap-type img {
  width: 100%;
  height: inherit;
}

/* 获取短信验证码 */
.login-content-inpwrap-sendcode {
  @include noselect;
  position: relative;
  margin-right: 15px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #4588ff;
  line-height: 28px;
  cursor: pointer;
}

.login-content-inpwrap-sendcode::after {
  display: block;
  position: absolute;
  content: " ";
  left: -10px;
  top: 6px;
  width: 1px;
  height: 20px;
  background: #cccccc;
}

/* 切换登录方式 */
/* 兄弟原生样式改变 变灰*/
.login-nav1:checked ~ .login-mode-nav li label,
.login-nav2:checked ~ .login-mode-nav li label,
.login-nav3:checked ~ .login-mode-nav li label,
.login-nav4:checked ~ .login-mode-nav li label {
  position: relative;
  font-weight: 500;
  color: #999999;
}

/*切换登录方式底部文本高亮*/
.login-nav1:checked ~ .login-mode-nav li:first-child label,
.login-nav2:checked ~ .login-mode-nav li:nth-child(2) label,
.login-nav3:checked ~ .login-mode-nav li:nth-child(3) label,
.login-nav4:checked ~ .login-mode-nav li:last-child label {
  font-weight: bold;
  color: #333333;
}

/* 切换登录方式底部伪类样式*/
.login-nav1:checked ~ .login-mode-nav li:first-child label::after,
.login-nav2:checked ~ .login-mode-nav li:nth-child(2) label::after,
.login-nav3:checked ~ .login-mode-nav li:nth-child(3) label::after,
.login-nav4:checked ~ .login-mode-nav li:last-child label::after {
  display: block;
  content: " ";
  position: absolute;
  bottom: -9px;
  left: 50%;
  width: 11px;
  height: 3px;
  background-color: #4588ff;
  transform: translateX(-50%);
}

// /* 登录模板切换 */

// .login-nav1:checked ~ .login-mode-content .login-mode-content-mode,
// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode,
// .login-nav3:checked ~ .login-mode-content .login-mode-content-mode,
// .login-nav4:checked ~ .login-mode-content .login-mode-content-mode {
//   display: none;
// }
// /*手机登录*/
// .login-nav1:checked ~ .login-mode-content .login-mode-content-mode:first-child ,
// /*验证码登录*/
// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode:nth-child(2),
// /*微信登录*/
// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode:nth-child(3),
// /*扫码登录*/
// .login-nav2:checked ~ .login-mode-content .login-mode-content-mode:last-child {
//   display: flex;
// }

#content-mode3,
#content-mode4 {
  display: none;
}

.login-tool.content1,
.login-footer.content1 {
  display: none;
}

/*操作栏*/
.login-tool {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 298px;
  height: 40px;
  margin: 0 auto;
  /* background-color: #44f1dd; */
}

.login-auto {
  display: flex;
  align-items: center;
  height: 12px;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #999999;
}

.login-auto input {
  margin-right: 11px;
}

.login-aspwd {
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #999999;
  cursor: pointer;
  display: inline-block;
  text-align: right;

  .login-forgetpwd {
    color: #333333;

    &:hover {
      color: #777777;
    }
  }
}

.login-scancode {
  color: #4588ff;
  margin-right: 11px;
}

/* 底部登录按钮行 */

.login-footer {
  padding-top: 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 298px;
  margin: 0 auto;
}

.login-footer-tips {
  /* margin-top: 21px; */
  display: flex;
  align-items: center;
  font-size: 12px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  color: #999999;
}

.login-footer-tips a {
  margin-left: 5px;
  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: 500;
  text-decoration: underline;
  color: #4588ff;
  cursor: pointer;
}

.login-footer-btn {
  @include noselect;
  width: 99px;
  height: 40px;
  line-height: 40px;
  font-size: 15px;
  text-align: center;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #ffffff;
  background: #4588ff;
  border-radius: 20px;
  cursor: pointer;
}

.login-footer-btn:hover {
  transform: translateY(-1px);
  box-shadow: 0 5px 10px #e6e4e4;
}

.login-footer-btn:active {
  box-shadow: 0 0 10px #e6e4e4;
}

.wm-antiplagiarism-header {
  @include flex-between;
  height: 64px;
  min-width: 1250px;
  background: #ffffff;
  box-shadow: 0px 0px 13px 0px rgba(155, 157, 170, 0.13);

  &-left {
    @include flex-start;
    height: 100%;

    &-logo {
      cursor: pointer;
      margin-left: 25px;
      img {
        image-rendering: -webkit-optimize-contrast;
        width: 190.9px;
        height: 53px;
      }
    }

    &-nav {
      margin-left: 55px;
      width: 500px;
      height: 100%;
      .nav-list {
        height: 100%;
        @include flex-between;

        &-item {
          @include flex-start;
          position: relative;
          height: 100%;
          cursor: pointer;
          font-size: 14px;

          .tit {
            font-size: 14px;
            color: #000;
          }

          .img {
            image-rendering: -webkit-optimize-contrast;
            transform: translateY(-4px);
            height: 19px;
          }
        }

        &-item.nav-list-item-active {
          &::before {
            content: "";
            position: absolute;
            top: 62px;
            left: calc(50% - 20px);
            width: 40px;
            height: 2px;
            background: #c12d1f;
          }

          .tit {
            font-weight: bold;
          }
        }
      }
    }
  }

  &-right {
    @include flex-start;
    margin-right: 30px;

    .netw {
      .blue {
        color: #4587ff;
      }
    }

    .netw,
    .download {
      @include flex-start;
      font-size: 14px;
      font-weight: 400;
      color: #000000;
      cursor: pointer;

      img {
        width: 16px;
        height: 16px;
        margin-right: 8px;
      }
    }

    .download {
      margin-left: 40px;
    }

    .user {
      @include flex-start;
      margin-left: 40px;
      cursor: pointer;

      img {
        width: 42px;
        height: 42px;
        margin-right: 10px;
      }

      .login-link {
        font-size: 14px;
        font-weight: 400;
        color: #4587dd;
      }
    }
  }

  .userInfoPopup {
    ::v-deep .el-dialog {
      margin: 0px;
      position: absolute;
      right: 101px;
      top: 64px;
      width: 320px;
      height: 300px;
      background: #ffffff;
      box-shadow: 0px 2px 5px 0px rgba(148, 145, 145, 0.49);
      border-radius: 4px;

      .el-dialog__header {
        padding: 0;
      }

      .el-dialog__body {
        padding: 0px 10px;
        position: relative;

        .user {
          padding: 10px 0 9px 0;
          border-bottom: 1px solid #f4f4f4;
          .user_box {
            display: flex;
          }

          .marketing {
            padding-left: 53px;
            font-size: 12px;
            color: #4587ff;

            a {
              display: flex;
              align-items: center;

              color: #4587ff;
              img {
                margin-right: 5px;
                width: 14px;
                height: 14px;
              }
            }
          }

          .headImg {
            position: relative;

            .grade {
              position: absolute;
              width: 14px;
              height: 14px;
              right: 5px;
              bottom: 0px;
            }

            .vipIcon {
              @include backgroundGroup("~assets/images/user-vip.png");
            }

            .svipIcon {
              @include backgroundGroup("~assets/images/user-svip.png");
            }
          }

          .user-text {
            margin-top: 4px;

            .user-name {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
            }

            .user-id {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #666666;
              margin-top: 9px;
            }
          }
          .user-character {
            margin-left: 19px;
            margin-bottom: 8px;
            font-size: 12px;
            font-weight: 500;
            color: #666666;
            align-self: flex-end;
          }
        }

        .progress {
          margin-top: 20px;

          .progress-title {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .left-text {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 500;
              color: #333333;
            }

            .right-text {
              .right-size {
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #c0c0c0;
                margin-right: 11px;
              }

              .right-btn {
                font-size: 12px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #4587ff;
                cursor: pointer;
              }
            }
          }

          .el-progress {
            margin-top: 10px;
          }
        }

        .vipInfo-bigBox {
          margin-top: 11px;
          padding: 14px 7px;
          border-bottom: 1px solid #f4f4f4;

          .vipInfo {
            height: 39px;
            @include backgroundGroup("~assets/images/user-vipBgc.png");
            display: flex;
            align-items: center;
            cursor: pointer;

            .vipInfo-name {
              display: flex;
              align-items: center;
              margin-left: 20px;

              .vipInfo-name-icon {
                width: 18px;
                height: 15px;
                @include backgroundGroup("~assets/images/user-anCrown.png");
                margin-right: 9px;
              }

              .vipInfo-name-text {
                font-size: 13px;
                font-family: PingFang SC;
                font-weight: 400;
                color: #ddb988;
              }
            }

            .vipInfo-line {
              margin-left: 13px;
              width: 1px;
              height: 16px;
              background: #ddb988;
            }

            .vipInfo-time {
              margin-left: 15px;
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 400;
              color: #ddb988;
            }
          }
        }

        .navGroup {
          display: flex;
          align-items: center;
          justify-content: space-around;
          height: 60px;
          border-bottom: 1px solid #f4f4f4;

          .navGroup-item {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            cursor: pointer;

            .navGroup-item-text {
              font-size: 12px;
              font-family: PingFang SC;
              font-weight: 700;
              color: #3b3e4a;
            }

            .navGroup-item-account {
              width: 16px;
              height: 16px;
              margin-bottom: 5px;
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-image: url("~assets/images/userAccount.png");
            }

            .navGroup-item-card {
              width: 16px;
              height: 16px;
              margin-bottom: 5px;
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-image: url("~assets/images/user-card.png");
            }

            .navGroup-item-horn {
              width: 16px;
              height: 16px;
              margin-bottom: 5px;
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-image: url("~assets/images/user-horn.png");
            }

            .navGroup-item-setUp {
              width: 16px;
              height: 16px;
              margin-bottom: 5px;
              background-repeat: no-repeat;
              background-size: 100% 100%;
              background-image: url("~assets/images/user-setUp.png");
            }
          }

          .navGroup-item:hover {
            .navGroup-item-account {
              background-image: url("~assets/images/userAccount-blue.png");
            }

            .navGroup-item-card {
              background-image: url("~assets/images/user-card-blue.png");
            }

            .navGroup-item-horn {
              background-image: url("~assets/images/user-horn-blue.png");
            }

            .navGroup-item-setUp {
              background-image: url("~assets/images/user-setUp-blue.png");
            }

            .navGroup-item-text {
              color: #4587ff;
            }
          }
        }

        .title {
          padding: 13px 5px 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .title-text {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #9b9b9b;
            cursor: pointer;
          }

          .title-btn {
            font-size: 12px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #9b9b9b;
            cursor: pointer;
          }
        }

        .vip {
          position: absolute;
          top: 20px;
          right: 0;
          width: 40px;
          height: 20px;
          @include backgroundGroup("~assets/images/user-bgcyellow.png");
          text-align: center;
          line-height: 20px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }

        .svip {
          position: absolute;
          top: 20px;
          right: 0;
          width: 47.6px;
          height: 20px;
          @include backgroundGroup("~assets/images/user-bgcred.png");
          text-align: center;
          line-height: 20px;
          font-size: 12px;
          font-family: PingFang SC;
          font-weight: bold;
          color: #ffffff;
        }
      }
    }
  }
}

// 消息中心弹窗
.message_dialog {
  ::v-deep .el-dialog {
    position: absolute;
    top: 65px;
    width: 47%;
    right: 80px;

    .el-dialog__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      background-color: #f4f6f7;

      .el-dialog__title {
        font-size: 14px;
      }

      .el-dialog__headerbtn {
        position: static;
      }
    }

    .el-dialog__body {
      padding: 0;
    }
  }

  ::v-deep .el-tabs__content {
    height: 100%;
    overflow: auto;
  }
  ::v-deep .el-tabs--left .el-tabs__header.is-left {
    margin-right: 0;
  }

  ::v-deep .el-tabs__item {
    height: 60px;
    display: flex !important;
    align-items: center;
  }

  ::v-deep .is-active {
    background-color: #ededed;
  }

  ::v-deep .el-tab-pane {
    position: relative;
    // height: 100%;

    .imgclass {
      margin: 80px auto;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;

      .img {
        display: block;
        width: 116px;
        height: 122px;
        margin-bottom: 20px;
      }
    }
  }

  .comments_bottom {
    z-index: 20;
    box-sizing: border-box;
    position: absolute;
    bottom: 0px;
    width: 71%;
    right: 1px;
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-size: 14px;
    background-color: #f4f6f7;

    .delete {
      cursor: pointer;
      color: red;
    }
  }

  .message_tabs {
    .user_box {
      display: flex;
      .user_img {
        img {
          border-radius: 50%;
          margin-right: 10px;
          width: 26px;
          height: 26px;
        }
      }

      .user_info {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        .user_name {
          display: flex;
          justify-content: space-between;
          height: 30px;
          .name {
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
          }
        }
        .user_content {
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .content {
            width: 150px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            color: #ccc;
          }
          .sum {
            text-align: center;
            line-height: 16px;
            border-radius: 50%;
            background-color: red;
            color: #fff;
            width: 16px;
            height: 16px;
            font-size: 8px;
          }
        }
      }
    }

    .user_message {
      .user {
        padding: 15px;
        box-sizing: border-box;
        background-color: #fff;
      }

      .message {
        position: relative;
        display: flex;
        flex-direction: column;
        padding: 0 20px;
        height: 450px;
        overflow: auto;
        background-color: #ededed;
        text-align: center;
        .date {
          padding: 5px;
          display: inline-block;
          background-color: #dbdbdb;
          color: #fff;
          margin: 20px auto;
        }
        .left_mesage {
          align-items: center;
          margin: 10px 0;
          display: flex;
          width: 50%;

          img {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            margin-right: 20px;
          }

          .text {
            padding: 10px;
            background-color: #fff;
            border-radius: 10px;
            line-height: 1.5;
          }
        }

        .rigth_mesage {
          position: absolute;
          right: 20px;
          display: flex;
          width: 50%;
          margin: 10px 0;
          justify-content: flex-end;
          text-align: right;
          align-items: center;

          img {
            border-radius: 50%;
            width: 45px;
            height: 45px;
            margin-left: 20px;
          }

          .text {
            padding: 10px;
            background-color: #fff;
            border-radius: 10px;
            line-height: 1.5;
          }
        }
      }

      .reply {
        position: relative;
        ::v-deep .el-textarea__inner {
          border: none;
          height: 165px;
          resize: none;
          font-size: 16px;
        }

        ::v-deep .el-input__count {
          right: 100px;
        }

        .el-button {
          position: relative;
          left: 87%;
          bottom: 30px;
        }
      }
    }
    .message_item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 220px;
      text-align: left;

      .img_box {
        display: flex;
        align-items: center;

        img {
          margin-right: 10px;
          width: 26px;
          height: 26px;
        }
      }

      .sum {
        text-align: center;
        line-height: 16px;
        border-radius: 50%;
        background-color: red;
        color: #fff;
        width: 16px;
        height: 16px;
        font-size: 8px;
      }
    }

    .comments {
      box-sizing: border-box;
      position: relative;
      height: 100%;
      overflow: auto;
      padding-bottom: 34px;

      .message_item_box {
        padding: 10px 20px;

        .message_title {
          font-weight: 800;
          font-size: 16px;
          padding: 0 0 20px 0;
        }

        .message_content {
          font-size: 14px;
        }
      }

      .img {
        margin: 80px auto;
        text-align: center;
        display: flex;
        align-items: center;
        flex-direction: column;

        img {
          display: block;
          width: 116px;
          height: 122px;
          margin-bottom: 20px;
        }
      }

      .comments_top {
        box-sizing: border-box;
        padding: 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid #cccc;

        .text {
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 800;
          color: #000;
        }

        .operation {
          color: #999999;
          cursor: pointer;
        }

        .red {
          color: red;
          cursor: pointer;
        }
      }

      .comments_center {
        box-sizing: border-box;
        padding: 20px;
        border-bottom: 1px solid #ccc;

        .center_box_top {
          box-sizing: border-box;
          display: flex;
          justify-content: space-between;
          padding: 10px;

          .left {
            width: 100%;
            font-size: 14px;
            font-family: PingFang SC;
            color: #000;

            .choose {
              align-items: center;
              position: relative;

              .el-checkbox {
                position: absolute;
                left: -20px;
                top: 20px;

                ::v-deep .el-checkbox__label {
                  display: none;
                }
              }

              .choose_box {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .title {
                  line-height: 1.5;
                  display: -webkit-box;
                  overflow: hidden;
                  -webkit-box-orient: vertical;
                  -webkit-line-clamp: 1;
                  color: #000;
                  font-weight: 800;
                }

                .rigth {
                  color: #999999;
                }
              }
            }
          }
        }

        .center_box_bottom {
          margin-top: 10px;
          line-height: 1.5;

          .color_red {
            color: red;
            cursor: pointer;
          }
        }

        .praise_top {
          display: flex;
          align-items: center;
          font-weight: 800;

          img {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            border-radius: 50%;
          }
        }

        .praise_center {
          margin: 10px 0;
          display: flex;
          align-items: center;

          img {
            margin-left: 5px;
            width: 16px;
            height: 16px;
          }
        }

        .praise_bottom {
          padding: 20px;
          background-color: #fbfbfb;

          .title {
            margin-bottom: 10px;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            font-weight: 800;
            font-size: 16px;
          }

          .p {
            line-height: 1.5;
            display: -webkit-box;
            overflow: hidden;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
      }
    }
  }
}
</style>
