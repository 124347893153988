import Vue from "vue";
import vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(vuex);

import { checkUserRealName } from "api/copyrightService";

const SET_USER_INFO = "SET_USER_INFO";
const MUTATE_REAL_NAME = "MUTATE_REAL_NAME";
const CHECK_REAL_NAME = "CHECK_REAL_NAME";
const CHECK_USER_OPTIONS = "CHECK_USER_OPTIONS";

const state = {
  user_info: null,
  sidebarActive: 0,
  imgList: [],
  copyrightServiceSideBar: [
    {
      title: "版权实务",
      name: "CopyrightPractice",
      active: true,
    },
    {
      title: "信息汇编",
      name: "InfoCompileIndex",
      active: false,
    },
    {
      title: "实名认证",
      name: "RealName",
      active: false,
    },
    {
      title: "收录确权",
      name: "Included",
      active: false,
    },
    {
      title: "会员服务",
      name: "VipServe",
      active: false,
    },
    {
      title: "电子数据指纹核验",
      name: "Fingerprint",
      active: false,
    },
  ],
  realNameData: null,
  onlineBasePath: "https://fafs.antiplagiarize.com/",
  loading: false,
};

const mutations = {
  //设置用户信息
  [SET_USER_INFO](state, payload) {
    state.user_info = payload.userInfo;
  },
  GetimgList(state, payload) {
    state.imgList = payload;
  },
  //实名验证请求
  [MUTATE_REAL_NAME](state, payload) {
    state.realNameData = payload;
  },
  /**
   * 全局验证登录和实名信息
   * @param { Boolean } payload.ignoreReal 是否忽略实名验证
   * @param { Function } payload.afterCheck 在验证完成后回调
   * */
  [CHECK_USER_OPTIONS](state, payload) {
    const vm = new Vue();
    if (!state.user_info) {
      vm.$message({
        type: "success",
        message: "请先登录账号",
      });
      vm.$bus.$emit("unAuthorized");
      return;
    }
    if (
      !payload.ignoreReal &&
      (!state.realNameData || state.realNameData.examine)
    ) {
      vm.$message({
        type: "success",
        message: "请先完成实名认证",
      });
      vm.$bus.$emit("realNamePopup");
      return;
    }
    if (payload.afterCheck && typeof payload.afterCheck === "function") {
      payload.afterCheck();
    }
  },
  ChangeSideBarId(state, payload) {
    state.sidebarActive = payload;
  },
  changeCopyrightServiceSideBar(state, payload) {
    state.copyrightServiceSideBar.map((item) => {
      item.active = false;
    });
    payload.item.active = true;
    state.copyrightServiceSideBar.map((item) => {
      if (item.name == payload.item.name) {
        item = payload.item;
      }
    });
  },
  handleChangeCopyrightServiceSideBar(state) {
    let arr = [];
    state.copyrightServiceSideBar.forEach((item) => {
      if (item.title != "信息汇编" && item.title != "收录确权") {
        arr.push(item);
      }
    });
    state.copyrightServiceSideBar = arr;
  },
  //设置全局loading状态
  playLifeLoading(state, status) {
    state.loading = status;
  },
};

const actions = {
  [CHECK_REAL_NAME]({ commit }) {
    checkUserRealName()
      .then((res) => {
        if (res.code == 200) {
          commit(MUTATE_REAL_NAME, res.data);
        } else if (res.code == 2016) {
          commit(MUTATE_REAL_NAME, {
            ...res.data,
            examine: true,
          });
        } else {
          commit(MUTATE_REAL_NAME, null);
        }
      })
      .catch((e) => {
        commit(MUTATE_REAL_NAME, null);
      });
  },
};

export default new vuex.Store({
  state,
  mutations,
  actions,
  plugins: [createPersistedState()],
});
